import React, { ReactElement, useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { IInactivityCountDownProps } from './types';
import { SCModalOuterWrapper } from './styles';
import { TSDialog } from 'components/atoms/TSDialog';
import {
    SCBodyMedium,
    SCContentSectionResponsiveWrapper,
    SCContentSectionWrapper,
    SCSectionSubHeadDigitalBlack
} from 'styles/global-styles';
import dayjs from 'dayjs';
import { AlertLocationTypesEnum, AlertTypeEnum } from 'enums/AlertEnums';
import { Routes } from 'routes';
import { AlertContext, IAlertTypeState } from 'context/Alert';
import { TSButton } from 'components/atoms/TSButton';
import { TSButtonSizeEnum, TSButtonVariantsEnum } from 'enums/TSButtonVariants';
import { FlexAlignItemsEnum, FlexDirectionEnum, FlexJustifyContentEnum } from 'enums/StyleTypes';

const InactivityCountDown = ({ setShowModal, eventHandler }: IInactivityCountDownProps): ReactElement => {
    const { updateContextStateAlert } = React.useContext(AlertContext) as IAlertTypeState;
    const { oktaAuth } = useOktaAuth();
    const [intervalInstance, setIntervalInstance] = useState<NodeJS.Timer>();
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    /**
     * Interval period used for timer
     **/
    const countdownInterval = 1000;
    const startCountdown = (): void => {
        const in5 = new Date(Date.now() + 5 * 60000);
        setIntervalInstance(
            setInterval((): void => {
                const now = new Date().getTime();
                const distance = dayjs(in5).valueOf() - now;
                const minutes = Math.floor((dayjs(distance).valueOf() % (1000 * 60 * 60)) / (1000 * 60));
                setMinutes(minutes);
                const seconds = Math.floor((dayjs(distance).valueOf() % (1000 * 60)) / 1000);
                setSeconds(seconds);
                if (minutes <= 0 && seconds <= 0) {
                    handleLogout().then(() => {
                        clearIntervalInstance();
                        setShowModal(false);
                        updateContextStateAlert({
                            type: AlertTypeEnum.ERROR,
                            dismissible: true,
                            message: 'Your session timed out. please ',
                            callToAction: 'log in again.',
                            callToActionURL: Routes.CLAIMS_MANAGEMENT,
                            active: true,
                            alertLocation: AlertLocationTypesEnum.GLOBAL
                        });
                    });
                }
            }, countdownInterval)
        );
    };
    const clearIntervalInstance = (): void => {
        clearInterval(intervalInstance);
    };
    const handleLogout = async (): Promise<void> => {
        clearIntervalInstance();
        await oktaAuth.signOut();
    };
    useEffect(() => {
        intervalInstance && clearIntervalInstance();
        startCountdown();
    }, []);
    // console.info(
    //     '\n::::::::::::::::::::::::::::::::InactivityCountDown:::::::::::::::::::::::::::::::::',
    //     '\n::oktaAuth::',
    //     oktaAuth,
    //     '\n::authState::',
    //     authState,
    //     '\n::contextStateAlert::',
    //     contextStateAlert,
    //     '\n::showModal::',
    //     showModal,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );

    return (
        <TSDialog
            ariaLabel="econsent-dialog"
            id="econsent-dialog"
            closeButtonText="Extend Session"
            open={true}
            dialogSize={'md'}
            fullscreen={false}
            hideSecondaryCloseButton={true}
            handleClose={(): void => {
                setShowModal(false);
            }}
        >
            <SCModalOuterWrapper>
                <SCContentSectionWrapper
                    flexDirection={FlexDirectionEnum.COLUMN}
                    flexAlignment={FlexAlignItemsEnum.CENTER}
                    flexJustifyContent={FlexJustifyContentEnum.CENTER}
                    flexGap={'36px'}
                    sectionWidth={'100%'}
                >
                    <SCContentSectionResponsiveWrapper
                        flexDirection={FlexDirectionEnum.COLUMN}
                        flexAlignment={FlexAlignItemsEnum.FLEX_START}
                        flexGap={'24px'}
                        sectionWidth={'100%'}
                    >
                        <SCSectionSubHeadDigitalBlack>Session Timeout</SCSectionSubHeadDigitalBlack>
                        <SCBodyMedium>
                            {(minutes > 0 &&
                                `Your session will time out in ${minutes} minute and ${seconds} seconds. Do you want to extend extent your current
                            session?`) ||
                                `Your session will time out in ${seconds} seconds. Do you want to extend extent your current
                            session?`}
                        </SCBodyMedium>
                    </SCContentSectionResponsiveWrapper>
                    <SCContentSectionResponsiveWrapper
                        flexDirection={FlexDirectionEnum.COLUMN}
                        flexAlignment={FlexAlignItemsEnum.CENTER}
                        flexGap={'24px'}
                        sectionWidth={'100%'}
                    >
                        <TSButton
                            ariaLabel="closeButton"
                            tsVariant={TSButtonVariantsEnum.SIMPLE}
                            size={TSButtonSizeEnum.LARGE}
                            onClick={(): void => {
                                clearIntervalInstance();
                                eventHandler();
                                setShowModal(false);
                            }}
                        >
                            Extend Session
                        </TSButton>
                        <TSButton
                            ariaLabel="closeButton"
                            tsVariant={TSButtonVariantsEnum.UNDERLINE}
                            size={TSButtonSizeEnum.MEDIUM}
                            onClick={(): void => {
                                handleLogout().then((): void => setShowModal(false));
                            }}
                        >
                            Log out
                        </TSButton>
                    </SCContentSectionResponsiveWrapper>
                </SCContentSectionWrapper>
            </SCModalOuterWrapper>
        </TSDialog>
    );
};

export { InactivityCountDown };
