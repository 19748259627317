import styled, { keyframes, css, ThemeProps, FlattenInterpolation } from 'styled-components';
import {
    FlexAlignItemsEnum,
    FlexDirectionEnum,
    FlexJustifyContentEnum,
    FormFieldWidthEnum,
    TableContentOverflowWrapEnum,
    TextColorEnum,
    TextTransformEnum
} from 'enums/StyleTypes';
import { RefObject } from 'react';
interface IImage {
    imageURI: string;
}
interface IFlexContentDirection {
    flexDirection?: FlexDirectionEnum;
    flexAlignment?: FlexAlignItemsEnum;
}
interface IContentSectionWrapper {
    flexDirection?: FlexDirectionEnum;
    flexAlignment?: FlexAlignItemsEnum;
    flexAlignmentResponsive?: FlexAlignItemsEnum;
    flexJustifyContent?: FlexJustifyContentEnum;
    sectionWidth?: string;
    flexGap?: string;
    useRef?: RefObject<any>;
}
interface IImageWrapper {
    flexDirection?: FlexDirectionEnum;
}
interface ITableContents {
    overflowWrap?: TableContentOverflowWrapEnum;
    textTransform?: TextTransformEnum;
}
interface IFormFieldWidth {
    widthAmount?: FormFieldWidthEnum;
    flexAlignment?: FlexAlignItemsEnum;
}
interface ITextTransform {
    textTransform?: TextTransformEnum;
    textWeight?: string;
    textSize?: string;
    textColor?: FlattenInterpolation<ThemeProps<any>> | string;
}
interface ISpinnerWrapper {
    width?: undefined | `100vw` | `100%`;
}
interface IErrorLabel {
    visibility?: string;
}
export const onLoad = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
/*////////////////////////////////////////////////*/
/*//////////////////Global  Style Variables//////////////////*/
/*////////////////////////////////////////////////*/
export const $black: FlattenInterpolation<ThemeProps<any>> = css`
    ${(props): TextColorEnum => {
        return props.theme.tsTheme && props.theme.tsTheme.colors.primary.black;
    }}
`;
export const $white: FlattenInterpolation<ThemeProps<any>> = css`
    ${(props): string => props.theme.tsTheme && props.theme.tsTheme.colors.primary.white}
`;
export const $digitalBlack: FlattenInterpolation<ThemeProps<any>> = css`
    ${(props): string => props.theme.tsTheme && props.theme.tsTheme.colors.secondary.digitalBlack}
`;
export const $deepBlue: FlattenInterpolation<ThemeProps<any>> = css`
    ${(props): string => props.theme.tsTheme && props.theme.tsTheme.colors.secondary.deepBlue}
`;
export const $deepBlue20: FlattenInterpolation<ThemeProps<any>> = css`
    ${(props): string => props.theme.tsTheme && props.theme.tsTheme.colors.secondary.deepBlue20}
`;
export const $brightBlue: FlattenInterpolation<ThemeProps<any>> = css`
    ${(props): string => props.theme.tsTheme && props.theme.tsTheme.colors.secondary.brightBlue}
`;
export const $brightBlue20: FlattenInterpolation<ThemeProps<any>> = css`
    ${(props): string => props.theme.tsTheme && props.theme.tsTheme.colors.colorTints.brightBlue20}
`;
export const $darkGray: FlattenInterpolation<ThemeProps<any>> = css`
    ${(props): string => props.theme.tsTheme && props.theme.tsTheme.colors.secondary.darkGray}
`;
export const $yellow: FlattenInterpolation<ThemeProps<any>> = css`
    ${(props): string => props.theme.tsTheme && props.theme.tsTheme.colors.primary.yellow}
`;
export const $warningRed: FlattenInterpolation<ThemeProps<any>> = css`
    ${(props): string => props.theme.tsTheme && props.theme.tsTheme.colors.colorTints.warningRedText}
`;
export const $successGreenBackground: FlattenInterpolation<ThemeProps<any>> = css`
    ${(props): string => props.theme.tsTheme && props.theme.tsTheme.colors.functional.successGreenBackground}
`;
export const $errorRedBackground: FlattenInterpolation<ThemeProps<any>> = css`
    ${(props): string => props.theme.tsTheme && props.theme.tsTheme.colors.functional.errorRedBackground}
`;
export const $contrastGrey03: FlattenInterpolation<ThemeProps<any>> = css`
    ${(props): string => props.theme.tsTheme && props.theme.tsTheme.colors.greyScale.contrastGrey03}
`;
export const $contrastBlack: FlattenInterpolation<ThemeProps<any>> = css`
    ${(props): string => props.theme.tsTheme && props.theme.tsTheme.colors.greyScale.contrastBlack}
`;
export const $greyScaleWhite: FlattenInterpolation<ThemeProps<any>> = css`
    ${(props): string => props.theme.tsTheme && props.theme.tsTheme.colors.greyScale.white}
`;
export const SCHeroHeader = styled.h2`
    font-size: 72px;
    font-style: normal;
    font-weight: 700;
    line-height: 80px;
    color: ${$deepBlue};
    @media (max-width: 1024px) {
        font-size: 44px;
        font-style: normal;
        font-weight: 700;
        line-height: 60px;
    }
`;
/*////////////////////////////////////////////////*/
/*//////////////////Page  Styles//////////////////*/
/*////////////////////////////////////////////////*/
export const SCBaseThemeWrapper = styled.div`
    min-height: 100vh;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.5px;
`;
export const SCPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
    ${(props): string => {
        return props.theme.AlertState && props.theme.AlertState.active
            ? `height: calc(100vh - 190px); `
            : `height: calc(100vh - 120px)`;
    }};
    width: 100vw;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 36px 0 0 0;
    animation: ${onLoad} 0.55s ease-out;
    @media (max-width: 768px) {
        height: calc(100vh - 70px);
    }
    //border: red dashed 4px;
`;
export const SCFooterWrapper = styled.div`
    width: 100%;
    height: auto;
`;
export const SCContentDivision = styled.div`
    padding: 12px 12px 12px 12px;
    background-color: transparent;
`;
export const SCContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 1383px;
    margin: 0 0 100px 0;
    @media (max-width: 1440px) {
        max-width: calc(100vw - 230px);
    }
    @media (max-width: 1024px) {
        margin-left: 0;
    }
    @media (max-width: 768px) {
        max-width: 100%;
        padding: 0;
    }
`;
/*////////////////////////////////////////////////*/
/*//////////////////Font Styles//////////////////*/
/*////////////////////////////////////////////////*/
export const SCEyebrow = styled.h1`
    font-weight: 700;
    font-style: normal;
    text-transform: capitalize;
    color: ${$digitalBlack};
    font-size: 20px;
    line-height: 26px; /* 130% */
    letter-spacing: 4px;
    @media (max-width: 1024px) {
        font-size: 14px;
        line-height: 20px; /* 142.857% */
        letter-spacing: 2.8px;
    }
`;
export const SCHeadline = styled.h3`
    font-weight: 700;
    font-style: normal;
    color: ${$digitalBlack};
    font-size: 24px;
    line-height: 26px;
    @media (max-width: 1024px) {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 18.35px;
    }
`;
export const SCSubHead = styled.h4<ITextTransform>`
    font-weight: 700;
    font-style: normal;
    font-size: 48px;
    color: ${(props): FlattenInterpolation<ThemeProps<any>> | string => {
        return props.textColor ? props.textColor : $digitalBlack;
    }};
    text-transform: ${(props): string => {
        return props.textTransform ? props.textTransform : TextTransformEnum.CAPITALIZE;
    }};
    line-height: 60px;
    @media (max-width: 1024px) {
        font-size: 32px;
        line-height: 40px;
    }
`;
export const SCSectionSubHead = styled.h3<ITextTransform>`
    font-size: ${(props): string => {
        return props.textSize ? props.textSize : '33px';
    }};
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    color: ${(props): FlattenInterpolation<ThemeProps<any>> | string => {
        return props.textColor ? props.textColor : $deepBlue;
    }};
    text-transform: ${(props): string => {
        return props.textTransform ? props.textTransform : TextTransformEnum.INHERIT;
    }};
    @media (max-width: 1024px) {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
    }
`;
export const SCSectionSubHeadDigitalBlack = styled(SCSectionSubHead)`
    color: ${$digitalBlack};
`;
export const SCSectionBody = styled.h3<ITextTransform>`
    font-size: 16px;
    font-style: normal;
    font-weight: ${(props): string => {
        return props.textWeight ? props.textWeight : '400';
    }};
    line-height: 26px; /* 162.5% */
    letter-spacing: 0.457px;
    color: ${$digitalBlack};
`;
export const SCSectionInfo = styled.p`
    font-weight: 400 !important;
    font-style: normal;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.5px;
    padding: 16px 0 16px 0;
    @media (max-width: 1440px) {
        font-size: 24px;
        line-height: 30px;
        padding: 16px 0 16px 0;
    }
`;
export const SCTableContent = styled.td<ITableContents>`
    overflow-wrap: ${(props): string => {
        return props.overflowWrap === TableContentOverflowWrapEnum.ACTIVE ? 'anywhere' : 'normal';
    }};
    text-transform: ${(props): string => {
        return props.textTransform ? props.textTransform : TextTransformEnum.INHERIT;
    }};
    font-weight: 500;
    font-style: normal;
    color: ${$digitalBlack};
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.5px;
    width: 100%;
    padding: 16px 16px 16px 0;
`;
export const SCClaimSubSectionHead = styled.h2`
    font-weight: 700;
    font-style: normal;
    font-size: 24px;
    line-height: 36px;
    color: ${$darkGray};
    padding-right: 24px;
    @media (max-width: 1024px) {
        font-size: 20px;
        line-height: 26px;
        padding: 16px 0 16px 0;
    }
`;
export const SCLabel = styled.div`
    width: 100%;
    font-size: 13px;
    color: ${$deepBlue};
    text-transform: capitalize;
    letter-spacing: 0.06rem;
`;
export const SCInputLabel = styled.div`
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: ${$digitalBlack};
    text-transform: capitalize;
    padding: 0 0 12px 0;
`;
export const SCErrorLabel = styled.label<IErrorLabel>`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    letter-spacing: 0.5px;
    color: ${$warningRed};
    //max-height: 6px;
    visibility: ${(props): string => {
        return props.visibility ? props.visibility : 'visible';
    }};
`;
export const SCBody = styled.div`
    color: ${$black};
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 151.2%;
    letter-spacing: 0.5px;
`;
export const SCBodyMedium = styled.div`
    color: ${$black};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.457px;
    white-space: pre-line;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    gap: 24px;
`;
export const SCBodySmall = styled(SCBody)`
    font-size: 14px;
    color: ${$black};
    line-height: 21px;
    letter-spacing: 0.4px;
`;
export const SCBodyLarge = styled(SCBody)`
    font-size: 21px;
    color: ${$black};
    line-height: 38px;
    letter-spacing: 0.5px;
    @media (max-width: 1024px) {
        font-size: 14px;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.3px;
    }
`;
export const SCDisclaimer = styled.div`
    font-size: 12px;
    font-weight: lighter;
    p {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        margin: 0 0 16px 0;
    }
`;
export const SCUnorderedList = styled.ul`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 36px;
    width: 100%;
    list-style-type: disc;
    a {
        color: ${$brightBlue};
        cursor: pointer;
    }
    li {
        margin-left: 24px;
        font-size: 21px;
        font-style: normal;
        font-weight: 400;
        line-height: 38px; /* 180.952% */
        letter-spacing: 0.5px;
    }
    //border: #0074D9 dashed 1px;
`;
export const SCOrderedList = styled.ol`
    font-size: 16px;
    font-weight: normal;
    padding: 8px;
    list-style-type: decimal;
    margin-left: 8px;
    a {
        color: ${$brightBlue};
        cursor: pointer;
    }
    li {
        margin-left: 16px;
        padding: 4px 0 4px 0;
    }
`;
export const SCRadioWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: flex-start;
`;
/*////////////////////////////////////////////////*/
/*////////////////Component Styles////////////////*/
/*////////////////////////////////////////////////*/
export const SCContentDirectionWrapper = styled.div<IFlexContentDirection>`
    display: flex;
    flex-direction: ${(props): FlexDirectionEnum => {
        return props.flexDirection ? props.flexDirection : FlexDirectionEnum.COLUMN;
    }};
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: ${(props): FlexAlignItemsEnum => {
        return props.flexAlignment ? props.flexAlignment : FlexAlignItemsEnum.FLEX_START;
    }};
    align-content: flex-start;
    width: 100%;
    height: 100%;
    gap: 180px;
    @media (max-width: 1024px) {
        flex-direction: ${(props): FlexDirectionEnum => {
            return props.flexDirection ? props.flexDirection : FlexDirectionEnum.COLUMN_REVERSE;
        }};
        gap: 80px;
    }
`;
export const SCContentSectionWrapper = styled.div<IContentSectionWrapper>`
    display: flex;
    flex-direction: ${(props): FlexDirectionEnum => {
        return props.flexDirection ? props.flexDirection : FlexDirectionEnum.COLUMN;
    }};
    flex-wrap: nowrap;
    justify-content: ${(props): FlexJustifyContentEnum => {
        return props.flexJustifyContent ? props.flexJustifyContent : FlexJustifyContentEnum.SPACE_EVENLY;
    }};
    align-items: ${(props): FlexAlignItemsEnum => {
        return props.flexAlignment ? props.flexAlignment : FlexAlignItemsEnum.FLEX_START;
    }};
    align-content: flex-start;
    width: ${(props): string => {
        return props.sectionWidth ? props.sectionWidth : '100%';
    }};
    height: auto;
    gap: ${(props): string => {
        return props.flexGap ? props.flexGap : '0';
    }};
    @media (max-width: 1024px) {
        flex-direction: column;
        width: 100%;
        max-width: 100%;
    }
`;
export const SCContentSectionResponsiveWrapper = styled(SCContentSectionWrapper)`
    @media (max-width: 1024px) {
        align-items: ${(props): FlexAlignItemsEnum => {
            return props.flexAlignmentResponsive ? props.flexAlignmentResponsive : FlexAlignItemsEnum.CENTER;
        }};
    }
    @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
        max-width: 100%;
        padding: 0 32px 0 32px;
    }
`;
export const SCImageWrapper = styled.div<IImageWrapper>`
    display: flex;
    flex-direction: ${(props): FlexDirectionEnum => {
        return props.flexDirection ? props.flexDirection : FlexDirectionEnum.COLUMN;
    }};
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-end;
    gap: 24px;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    @media (max-width: 1024px) {
        flex-direction: column;
        gap: 40px;
    }
    @media (max-width: 768px) {
        padding: 0 32px 0 32px;
    }
`;
export const SCButtonsWrapper = styled.div<IImageWrapper>`
    display: flex;
    flex-direction: ${(props): FlexDirectionEnum => {
        return props.flexDirection ? props.flexDirection : FlexDirectionEnum.ROW;
    }};
    place-content: flex-start center;
    align-items: center;
    width: min-content;
    min-width: fit-content;
    height: auto;
    gap: 24px;
    @media (max-width: 1024px) {
        flex-direction: column;
        width: 100%;
        gap: 40px;
    }
    @media (max-width: 768px) {
        width: 100%;
        padding: 0 32px 0 32px;
    }
`;
export const SCHeroImageWrapper = styled(SCImageWrapper)`
    @media (max-width: 1440px) {
        flex-direction: column;
        gap: 40px;
    }
    @media (max-width: 1024px) {
        display: initial;
        padding: 0;
    }
`;
export const SCImageContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex-basis: 100%;
    justify-content: center;
    align-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    max-width: 50vw;
    min-width: auto;
    min-height: 150px;
    a {
        color: ${$brightBlue};
        cursor: pointer;
    }
    @media (max-width: 1024px) {
        max-width: 100%;
    }
`;
export const SCHeroImageContent = styled(SCImageContent)`
    @media (max-width: 1440px) {
        max-width: 100%;
    }
    @media (max-width: 1024px) {
        padding: 40px 0 0 0;
    }
    @media (max-width: 768px) {
        padding: 40px 32px 0 32px;
    }
`;
export const SCImageDistributorLogo = styled.img`
    height: auto;
    width: auto;
    max-height: 55px;
    animation: ${onLoad} 0.55s ease-out;
`;
export const SCImage = styled.div<IImage>`
    height: 60vw;
    max-height: 369px;
    width: 100%;
    background: url(${(props): string => {
            return props.imageURI;
        }})
        no-repeat center;
    background-size: cover;
    border-radius: 5px;
    @media (max-width: 1024px) {
        width: 100%;
        min-height: 395px;
        align-self: center;
    }
`;
export const SCHeroImage = styled(SCImage)`
    height: 100vw;
    max-height: 565px;
`;
export const SCFAQContentWrapper = styled(SCContentSectionWrapper)`
    @media (max-width: 1024px) {
        flex-direction: column;
        align-content: flex-start;
        align-items: flex-start;
    }
`;
export const SCThreeColumnWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    gap: 36px;
    @media (max-width: 1024px) {
        flex-direction: row;
        gap: 16px;
    }
`;
export const SCThreeColumnContentWrapper = styled(SCContentSectionWrapper)`
    width: 100%;
    @media (max-width: 1024px) {
        flex-direction: column;
        gap: 24px;
    }
    @media (max-width: 768px) {
        padding: 0 32px 0 32px;
    }
`;
interface IThreeColumnHeader {
    textColor: string;
    textAlign: string;
}
export const SCThreeColumnHeader = styled.div<IThreeColumnHeader>`
    width: 100%;
    font-weight: 700;
    font-style: normal;
    font-size: 22px;
    line-height: 30px;
    text-transform: capitalize;
    color: ${(props): FlattenInterpolation<ThemeProps<any>> | string => {
        return props.textColor ? props.textColor : $black;
    }};
    text-align: ${(props): string => {
        return props.textAlign ? props.textAlign : 'center';
    }};
    padding: 32px 0 32px 0;
    @media (max-width: 1024px) {
        font-size: 18px;
    }
`;
export const SCThreeColumnContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    max-width: 45%;
    gap: 24px;
    @media (max-width: 1024px) {
        gap: 16px;
        max-width: 100%;
    }
`;
export const SCFormRefWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 36px;
    width: 100%;
    min-height: 33vh;
    animation: ${onLoad} 0.55s ease-out;
`;
export const SCSectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 24px;
    width: 100%;
    animation: ${onLoad} 0.55s ease-out;
`;
export const SCFormTopNavWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    width: 100%;
    padding: 16px 0 16px 0;
    @media (max-width: 768px) {
        flex-wrap: wrap;
    }
`;
export const SCFormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    width: 100%;
    form {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-content: center;
        align-items: flex-start;
        gap: 64px;
    }
    @media (max-width: 768px) {
        padding: 0;
    }
`;
export const SCTempDateFieldWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    width: 100%;

    div {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-content: center;
        align-items: center;
        width: 100%;

        div {
            width: 100%;

            div {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-end;
                align-content: center;
                align-items: center;
                width: fit-content;
                height: min-content;
            }
        }

        input {
            width: 100%;
        }
    }
`;
export const SCHRElement = styled.div`
    border-top: 1px solid rgba(242, 242, 242, 1);
    width: 100%;
    margin: 32px 0 32px 0;
`;
export const SCForm = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    width: 100%;
    padding: 16px;
    gap: 24px;
`;
export const SCFormNavigationWrapper = styled.div`
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
    padding: 32px 16px 16px 0;
`;
export const SCElementsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    gap: 48px;
    padding: 0;
    @media (max-width: 1024px) {
        gap: 36px;
    }
`;
export const SCElementWrapper = styled.div<IFormFieldWidth>`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: ${(props): FlexAlignItemsEnum => {
        return props.flexAlignment ? props.flexAlignment : FlexAlignItemsEnum.FLEX_START;
    }};
    height: auto;
    min-height: 72px;
    width: 100%;
    flex-basis: ${(props): string => {
        if (props.widthAmount === FormFieldWidthEnum.SINGLE) {
            return '30%';
        } else if (props.widthAmount === FormFieldWidthEnum.DOUBLE) {
            return '40%';
        } else if (props.widthAmount === FormFieldWidthEnum.TRIPLE) {
            return '70%';
        } else if (props.widthAmount === FormFieldWidthEnum.QUADRUPLE) {
            return '100%';
        } else {
            return '100%';
        }
    }};
    max-width: ${(props): string => {
        if (props.widthAmount === FormFieldWidthEnum.SINGLE) {
            return '31%';
        } else if (props.widthAmount === FormFieldWidthEnum.DOUBLE) {
            return '47%';
        } else if (props.widthAmount === FormFieldWidthEnum.TRIPLE) {
            return '65.5%';
        } else if (props.widthAmount === FormFieldWidthEnum.QUADRUPLE) {
            return '100%';
        } else {
            return '100%';
        }
    }};
    flex-grow: ${(props): string => {
        return props.widthAmount ? props.widthAmount : `0`;
    }};
    input {
        font-size: 18px;
    }
    textarea {
        font-size: 18px;
        width: 100%;
        margin: 0;
        resize: vertical;
    }
    animation: ${onLoad} 0.55s ease-out;
    @media (max-width: 1024px) {
        flex-basis: 100%;
        max-width: 100%;
    }
    @media (max-width: 768px) {
        flex-basis: 100%;
    }
`;
export const SCModalWrapper = styled.div`
    #modal-window {
        max-width: 512px;
        background-color: ${$white};
        border-radius: 5px;
    }
    background-color: ${$black};
`;
export const SCSpinnerWrapper = styled.div<ISpinnerWrapper>`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 75vh;
    animation: ${onLoad} 0.55s ease-out;
    width: ${(props): string => {
        return props.width != null ? `${props.width}` : `100vw`;
    }};
`;
