import React, { useEffect, useState, MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { ITableEnhancedExternalProps } from './types';
import { ClaimsManagementStateContext } from 'context/claimsManagementContext';
import { IClaimsManagementData, IClaimsManagementState } from 'types/claims-management';
import { Order } from 'components/molecules/TableHeadEnhanced';
import { DataExternal } from 'components/pages/ClaimsManagement';
import { convertToCSV, createCSVDownloadLink } from 'utils/helpers/helpers';
import { SCTableOverrides } from 'components/molecules/TableEnhanced/styles';
import {
    Box,
    Button,
    FormControl,
    Table,
    TableBody,
    TableContainer,
    TablePagination,
    TableRow,
    TableCell
} from '@mui/material';
import { headCells, csvHeadCells, TableHeadEnhancedExternal } from 'components/molecules/TableHeadEnhancedExternal';
import { ClaimTypesEnum } from 'enums';
import { ClaimLookupDataStateContext } from 'context/claimLookupDataContext';
import { IClaimLookupResponseData, IClaimLookupResponseDataState } from 'types/claim-lookup';
import { ClaimLookupService } from 'api/ClaimLookup';
import { Routes } from 'routes/Routes';
import { SCErrorLabel } from 'styles/global-styles';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { TSButton } from 'components/atoms/TSButton';
import { TSButtonSizeEnum, TSButtonVariantsEnum } from 'enums/TSButtonVariants';
import { DistributorConfigurationStateContext } from 'context/distributorDataContext';
import { IClientContextState } from 'types/client-context';

dayjs.extend(utc);
dayjs.extend(timezone);
const timeZone = 'America/Chicago';

const TableEnhancedExternal = ({ children }: ITableEnhancedExternalProps): JSX.Element => {
    const history = useHistory();
    const { claimsManagementTableState } = React.useContext(ClaimsManagementStateContext) as IClaimsManagementState;
    const { claimLookupResponseDataState, saveClaimLookupResponseDataState } = React.useContext(
        ClaimLookupDataStateContext
    ) as IClaimLookupResponseDataState;
    const { clientContextDataState } = React.useContext(DistributorConfigurationStateContext) as IClientContextState;
    const [ClaimNotFoundMessage, setClaimNotFoundMessage] = React.useState<string | null>(null);
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof DataExternal>('claimNumber');
    const [selected, setSelected] = useState<readonly string[]>([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchClaimNumber, setSearchClaimNumber] = useState('');
    const [claimsManagementTable, setClaimsManagementTable] = useState<IClaimsManagementData[]>([]);
    const [minimumDate, setMinimumDate] = useState();
    const [maximumDate, setMaximumDate] = useState();
    const [allDatesValid, setAllDatesValid] = useState(false);
    const viewClaimDetail = async (claimLookParam: any): Promise<void> => {
        ClaimLookupService.postClaimWithResponse(claimLookParam)
            .then((response: { data: { payload: IClaimLookupResponseData } }) => {
                if (response.data.payload) {
                    const data = response.data.payload as IClaimLookupResponseData;
                    data.shouldDisplayResult = true;
                    saveClaimLookupResponseDataState(data);
                    history.push(Routes.CLAIM_LOOKUP, { from: Routes.CLAIMS_MANAGEMENT });
                } else {
                    claimLookupResponseDataState.shouldDisplayNoResult = true;
                    saveClaimLookupResponseDataState(claimLookupResponseDataState);
                }
            })
            .catch((error) => {
                setClaimNotFoundMessage('Something went wrong !! Please try again later');
                console.error(`error: ${error.message}`);
            });
    };
    useEffect(() => {
        setClaimsManagementTable(claimsManagementTableState);
    }, [claimsManagementTableState]);
    useEffect(() => {
        maximumDate && minimumDate && isFinite(+maximumDate) && isFinite(+minimumDate)
            ? setAllDatesValid(true)
            : setAllDatesValid(false);
    }, [minimumDate, maximumDate]);
    const createData = (
        claimNumber: string,
        borrowerFullName: string,
        loanNumber: string,
        peril: string,
        claimDecision: string,
        amountPayable: string,
        decisionReason: string,
        decisionDateTime: string,
        claimStatusUpdatedDateTime: string,
        claimSubmitDate: string,
        reviewDetails: string
    ): DataExternal => {
        return {
            claimNumber,
            borrowerFullName,
            loanNumber,
            peril,
            claimDecision,
            amountPayable,
            decisionReason,
            decisionDateTime,
            claimStatusUpdatedDateTime,
            claimSubmitDate,
            reviewDetails
        };
    };
    const rows: DataExternal[] = claimsManagementTable.map(
        (element: IClaimsManagementData, index: number, array: IClaimsManagementData[]) => {
            const row: DataExternal = createData(
                element.ClaimNumber,
                `${element.NameLast}, ${element.NameFirst}`,
                element.LoanNumber,
                element.Peril,
                element.ClaimDecision?.toLowerCase(),
                element.AmountPayable,
                element.DecisionReason,
                element.DecisionDateTimeUTC,
                element.ClaimStatusLastUpdateDateTimeUTC,
                element.ClaimSubmitDateTimeUTC,
                ' ' //used for the view details column
            );
            return row;
        }
    );
    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof DataExternal): void => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>): undefined | void => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.claimNumber as unknown as string);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };
    const handleClick = (event: React.MouseEvent<unknown>, claimNumber: string): void => {
        const selectedIndex = selected.indexOf(claimNumber);
        let newSelected: readonly string[] = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, claimNumber);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };
    const descendingComparator = <T,>(a: T, b: T, orderBy: keyof T): number => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    };
    const getComparator = <Key extends keyof any>(
        order: Order,
        orderBy: Key
    ): ((a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number) => {
        return order === 'desc'
            ? (a, b): number => descendingComparator(a, b, orderBy)
            : (a, b): number => -descendingComparator(a, b, orderBy);
    };
    // TODO: sort return type(no any)
    const stableSort = <T,>(array: readonly T[], comparator: (a: T, b: T) => number): any => {
        const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            return order !== 0 ? order : a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    };
    const handleChangePage = (event: unknown, newPage: number): void => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const isSelected = (claimNumber: string): boolean => selected.indexOf(claimNumber) !== -1;
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    const exportTableToCSV = (): void => {
        const rowsDeepCopy = structuredClone(rows);
        const csvRows = [...rowsDeepCopy];
        csvRows.map((rowObject, index) => {
            rowObject.amountPayable !== undefined && (rowObject.amountPayable = `$${rowObject.amountPayable}`);
            rowObject.decisionDateTime !== undefined &&
                (rowObject.decisionDateTime = `${dayjs(rowObject.decisionDateTime).format('MMMM D YYYY')}`);
            rowObject.claimStatusUpdatedDateTime !== undefined &&
                (rowObject.claimStatusUpdatedDateTime = `${dayjs
                    .utc(rowObject.claimStatusUpdatedDateTime as string)
                    .tz(timeZone)
                    .format('MMMM D YYYY h:mm A')}`);
            rowObject.claimSubmitDate !== undefined &&
                (rowObject.claimSubmitDate = `${dayjs
                    .utc(rowObject.claimSubmitDate as string)
                    .tz(timeZone)
                    .format('MMMM D YYYY h:mm A')}`);
        });
        createCSVDownloadLink(convertToCSV(csvRows, csvHeadCells), 'Search Results');
    };
    /*    console.info(
        '\n::::::::::::::::::::::::::::::::TableEnhancedExternal:::::::::::::::::::::::::::::::::',
        '\n::rows::',
        rows,
        '\n::claimsManagementTableState::',
        claimsManagementTableState,
        '\n::allDatesValid::',
        allDatesValid,
        '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    );*/

    return (
        <>
            {ClaimNotFoundMessage && <SCErrorLabel>{ClaimNotFoundMessage}</SCErrorLabel>}
            <TableContainer>
                <SCTableOverrides>
                    <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'small'}>
                        <TableHeadEnhancedExternal
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.claimNumber as string);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event): void => handleClick(event, row.claimNumber as string)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={`table-row-${index}`}
                                            selected={isItemSelected}
                                        >
                                            <TableCell align="left">
                                                <FormControl fullWidth sx={{ m: 1, width: '15ch' }} variant="standard">
                                                    {row.claimNumber}
                                                </FormControl>
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                            >
                                                <FormControl
                                                    fullWidth
                                                    sx={{ m: 1, width: 'auto', minWidth: '15ch' }}
                                                    variant="standard"
                                                >
                                                    {row.borrowerFullName}
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="left">
                                                <FormControl
                                                    fullWidth
                                                    sx={{ m: 1, width: '15ch', overflowWrap: 'break-word' }}
                                                    variant="standard"
                                                >
                                                    {row.loanNumber}
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="left">
                                                <FormControl fullWidth sx={{ m: 1, width: '10ch' }} variant="standard">
                                                    {row.peril === ClaimTypesEnum.Jobloss ? 'Job Loss' : 'Disability'}
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="left">
                                                <FormControl fullWidth sx={{ m: 1, width: '10ch' }} variant="standard">
                                                    {row.claimDecision}
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="left">
                                                <FormControl fullWidth sx={{ m: 1, width: '15ch' }} variant="standard">
                                                    {row.amountPayable && `$${row.amountPayable}`}
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="left">
                                                <FormControl fullWidth sx={{ m: 1, width: '15ch' }} variant="standard">
                                                    {row.decisionReason && `${row.decisionReason}`}
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="left">
                                                <FormControl fullWidth sx={{ m: 1, width: '15ch' }} variant="standard">
                                                    {row.decisionDateTime &&
                                                        dayjs(row.decisionDateTime).format('MMMM D YYYY')}
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="left">
                                                <FormControl fullWidth sx={{ m: 1, width: '25ch' }} variant="standard">
                                                    {row.claimStatusUpdatedDateTime &&
                                                        dayjs
                                                            .utc(row.claimStatusUpdatedDateTime as string)
                                                            .tz(timeZone)
                                                            .format('MMMM D YYYY h:mm A')}
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="left">
                                                <FormControl fullWidth sx={{ m: 1, width: '25ch' }} variant="standard">
                                                    {row.claimSubmitDate &&
                                                        dayjs
                                                            .utc(row.claimSubmitDate as string)
                                                            .tz(timeZone)
                                                            .format('MMMM D YYYY h:mm A')}
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControl fullWidth sx={{ m: 1, width: '11ch' }} variant="standard">
                                                    <TSButton
                                                        tsVariant={TSButtonVariantsEnum.UNDERLINE}
                                                        size={TSButtonSizeEnum.SMALL}
                                                        ariaLabel={'button to output CSV of Search Results'}
                                                        onClick={(): void => {
                                                            const claimManagementObject =
                                                                claimsManagementTableState.find(
                                                                    (claimsManagementDataObject) =>
                                                                        claimsManagementDataObject.ClaimNumber ===
                                                                        row.claimNumber
                                                                );
                                                            viewClaimDetail({
                                                                claimNumber: row.claimNumber,
                                                                borrowerIdentifierKey:
                                                                    claimManagementObject?.BorrowerIdentifierKey
                                                                        .length == 10
                                                                        ? claimManagementObject?.BorrowerIdentifierKey.substring(
                                                                              6,
                                                                              10
                                                                          )
                                                                        : claimManagementObject?.BorrowerIdentifierKey,
                                                                distributorName:
                                                                    clientContextDataState?.payload?.distributorName ??
                                                                    ''
                                                            });
                                                        }}
                                                    >
                                                        View Details
                                                    </TSButton>
                                                </FormControl>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </SCTableOverrides>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <TSButton
                tsVariant={TSButtonVariantsEnum.UNDERLINE}
                size={TSButtonSizeEnum.MEDIUM}
                ariaLabel={'button to output CSV of Search Results'}
                onClick={(): void => {
                    exportTableToCSV();
                }}
            >
                Download CSV of Search Results
            </TSButton>
        </>
    );
};

export { TableEnhancedExternal };
