import React, { ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import { DistributorConfigurationStateContext } from 'context/distributorDataContext';
import { OverlayTypeContext } from 'context/overlayContext';
import { IClientContextApiResponse, IClientContextState } from 'types/client-context';
import {
    SCDistributorSelectorHeaderContent,
    SCDistributorSelectorHeaderWrapper,
    SCDistributorSelectorImageContent,
    SCDistributorSelectorImageWrapper,
    SCDistributorSelectorSCPageWrapper,
    SCDistributorSelectorSelectorContent,
    SCDistributorSelectorSelectorTitle,
    SCDistributorSelectorSelectorWrapper
} from './styles';
import { IOverlayTypeState } from 'types/overlay-context';
import {
    $darkGray,
    SCContentSectionResponsiveWrapper,
    SCContentSectionWrapper,
    SCContentWrapper,
    SCEyebrow,
    SCFooterWrapper,
    SCHeroHeader,
    SCHeroImage,
    SCSectionBody,
    SCSectionSubHead
} from 'styles/global-styles';
import { ClientContextsService } from 'api/ClientContextsService';
import { GlobalFooter } from '../GlobalFooter';
import { TSButton } from 'components/atoms/TSButton';
import { IDistributorSelectorProps } from './types';
import { ILandingPagePartnerOptions } from 'types/distributor-information';
import { TSAutocomplete } from 'components/atoms/TSAutocomplete';
import { Box } from '@mui/material';
import { distributorImages } from 'support/distributors';
import { TruStageLogoU } from 'components/atoms/TruStageLogoU';
import imageHero from 'assets/images/family_swing.jpg';
import { FlexAlignItemsEnum, FlexDirectionEnum, FlexJustifyContentEnum } from 'enums/StyleTypes';
import { LocalStorageKeys } from 'enums/LocalStorageKeys';
import { PartnersService } from 'api/PartnersService';
import { IPartnersApiResponse, IPartner } from 'types/partners';

const DistributorSelector = ({ children }: IDistributorSelectorProps): ReactElement => {
    const { saveClientContextDataState } = React.useContext(
        DistributorConfigurationStateContext
    ) as IClientContextState;
    const { overlayType, saveOverlayType } = React.useContext(OverlayTypeContext) as IOverlayTypeState;
    const [inProgressDistributorKey, setInProgressDistributorKey] = useState<string>();
    const [inProgressLoanServicerKey, setInProgressLoanServicerKey] = useState<string>();
    const [autoCompleteValue, setAutoCompleteValue] = React.useState('');
    const [livePartnerOptions, setLivePartnerOptions] = useState<ILandingPagePartnerOptions[]>([]);
    const getPartners = async (): Promise<void> => {
        const data = (await PartnersService()) as IPartnersApiResponse;
        if (data?.payload) {
            const finalOptions: ILandingPagePartnerOptions[] = data.payload?.map(
                (val: IPartner): ILandingPagePartnerOptions => {
                    return {
                        label: val.name,
                        distributorKey: val.distributorKey,
                        loanServicerKey: val.loanServicerKey,
                        image: undefined
                    };
                }
            );
            setLivePartnerOptions(finalOptions);
        }
    };
    const getClientConfiguration = async (distributorKey: string, loanServicerKey?: string): Promise<void> => {
        const data = (await ClientContextsService(distributorKey, loanServicerKey)) as IClientContextApiResponse;
        if (data?.payload != null) {
            saveClientContextDataState(data.payload);
        }
    };
    useEffect(() => {
        getPartners();
    }, []);
    const filterOptions = (options: any[]): any => {
        const newOptions = [];
        if (autoCompleteValue.length !== undefined && autoCompleteValue.length > 2) {
            return options.filter((option) => option.label.toLowerCase().includes(autoCompleteValue.toLowerCase()));
        }
        return newOptions as ILandingPagePartnerOptions[];
    };
    /*    console.info(
        '\n::::::::::::::::::::::::::::::::DistributorSelector:::::::::::::::::::::::::::::::::',
        "\n::localStorage.getItem('clientId')::",
        localStorage.getItem('clientId'),
        '\n::livePartnerOptions::',
        livePartnerOptions,
        '\n::inProgressDistributorKey::',
        inProgressDistributorKey,
        '\n::inProgressLoanServicerKey::',
        inProgressLoanServicerKey,
        '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    );*/

    return (
        <SCDistributorSelectorSCPageWrapper id="Takeover">
            <SCDistributorSelectorHeaderWrapper id="HeaderContent">
                <SCDistributorSelectorHeaderContent id="HeaderContent">
                    <TruStageLogoU />
                </SCDistributorSelectorHeaderContent>
            </SCDistributorSelectorHeaderWrapper>
            <SCContentWrapper id="TakeoverContent">
                <SCDistributorSelectorImageWrapper flexDirection={FlexDirectionEnum.ROW_REVERSE}>
                    <SCHeroImage imageURI={imageHero}></SCHeroImage>
                    <SCDistributorSelectorImageContent>
                        <SCEyebrow>Payment Guard</SCEyebrow>
                        <SCHeroHeader>Welcome to TruStage Payment Guard insurance</SCHeroHeader>
                        <SCSectionSubHead textColor={$darkGray}>
                            Your exclusive insurance, at no additional cost
                        </SCSectionSubHead>
                        <SCSectionBody style={{ fontWeight: '700' }}>
                            For unexpected covered job losses or disability
                        </SCSectionBody>
                    </SCDistributorSelectorImageContent>
                </SCDistributorSelectorImageWrapper>
                <SCDistributorSelectorSelectorWrapper>
                    <SCDistributorSelectorSelectorTitle>
                        To get started, select your institution.
                    </SCDistributorSelectorSelectorTitle>
                    <SCContentSectionResponsiveWrapper
                        flexDirection={FlexDirectionEnum.COLUMN}
                        flexAlignment={FlexAlignItemsEnum.FLEX_START}
                        flexGap={'24px'}
                    >
                        <SCDistributorSelectorSelectorContent>
                            Start here to file a claim, check your claim status, view your certificate of insurance or
                            learn more about your Payment Guard benefits.
                        </SCDistributorSelectorSelectorContent>
                        <SCContentSectionWrapper
                            flexDirection={FlexDirectionEnum.ROW}
                            flexAlignment={FlexAlignItemsEnum.FLEX_END}
                            flexJustifyContent={FlexJustifyContentEnum.FLEX_START}
                            flexGap={'16px'}
                        >
                            <SCContentSectionWrapper
                                flexDirection={FlexDirectionEnum.ROW}
                                flexAlignment={FlexAlignItemsEnum.FLEX_END}
                                flexJustifyContent={FlexJustifyContentEnum.FLEX_START}
                                sectionWidth={'639px'}
                            >
                                <TSAutocomplete
                                    id="DistrubtorDropDownList"
                                    filterOptions={filterOptions}
                                    options={livePartnerOptions}
                                    placeholder={'Search the name of your lending institution here...'}
                                    onFreeSolo={true}
                                    renderOption={(props, option): ReactElement<any, any> => {
                                        const distributorIcon = `images/icons/${
                                            distributorImages[option.distributorKey.toLowerCase()]
                                        }`;
                                        const loanServicerIcon = `images/icons/${
                                            option.loanServicerKey &&
                                            distributorImages[option.loanServicerKey.toLowerCase()]
                                        }`;
                                        return (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                <img
                                                    loading="lazy"
                                                    width="20"
                                                    src={
                                                        option.loanServicerKey
                                                            ? loanServicerIcon
                                                            : option.distributorKey && distributorIcon
                                                    }
                                                    alt=""
                                                />
                                                {option.label}
                                            </Box>
                                        );
                                    }}
                                    onChange={(event: SyntheticEvent, option: ILandingPagePartnerOptions): void => {
                                        setInProgressDistributorKey(option?.distributorKey);
                                        setInProgressLoanServicerKey(option?.loanServicerKey);
                                    }}
                                    onInputChange={(event: React.SyntheticEvent, value: string): void => {
                                        setAutoCompleteValue(value);
                                        setInProgressDistributorKey(undefined);
                                        setInProgressLoanServicerKey(undefined);
                                    }}
                                    /** Leaving AutoSelect (tab-selection, basically) off. It does not behave well for this dropdown specifically */
                                    autoSelect={false}
                                />
                            </SCContentSectionWrapper>
                            <TSButton
                                id="continueButton"
                                ariaLabel={'Continue'}
                                disabled={!inProgressDistributorKey}
                                onClick={(): void => {
                                    localStorage.removeItem(LocalStorageKeys.DISTRIBUTOR_KEY);
                                    localStorage.removeItem(LocalStorageKeys.LOAN_SERVICER_KEY);
                                    if (inProgressDistributorKey)
                                        localStorage.setItem(
                                            LocalStorageKeys.DISTRIBUTOR_KEY,
                                            inProgressDistributorKey as string
                                        );
                                    if (inProgressLoanServicerKey)
                                        localStorage.setItem(
                                            LocalStorageKeys.LOAN_SERVICER_KEY,
                                            inProgressLoanServicerKey as string
                                        );
                                    saveOverlayType({ type: undefined, reviewState: false });
                                    getClientConfiguration(
                                        inProgressDistributorKey as string,
                                        inProgressLoanServicerKey as string
                                    );
                                }}
                            >
                                Continue
                            </TSButton>
                        </SCContentSectionWrapper>
                    </SCContentSectionResponsiveWrapper>
                </SCDistributorSelectorSelectorWrapper>
            </SCContentWrapper>
            <SCFooterWrapper>
                <GlobalFooter />
            </SCFooterWrapper>
        </SCDistributorSelectorSCPageWrapper>
    );
};
export { DistributorSelector };
