export const distributorImages = {
    basis: 'basis-icon.svg',
    beem: 'beem-icon.svg',
    comfirstcu: 'comfirstcu-icon.svg',
    greenlyne: 'greenlyne-icon.svg',
    happymoney: 'happy-money.svg',
    listo: 'listo-logo.svg',
    peopledrivencu: 'peopledrivencu-icon.svg',
    qcash: 'qcash-icon.svg',
    splash: 'splash-icon.svg',
    way: 'way.com-icon.svg',
    zirtue: 'zirtue-icon.svg'
};
