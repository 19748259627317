export enum FormFieldWidthEnum {
    SINGLE = '1',
    DOUBLE = '2',
    TRIPLE = '3',
    QUADRUPLE = '4'
}
export enum FlexDirectionEnum {
    ROW = 'row',
    ROW_REVERSE = 'row-reverse',
    COLUMN = 'column',
    COLUMN_REVERSE = 'column-reverse'
}
export enum FlexAlignItemsEnum {
    FLEX_START = 'flex-start',
    FLEX_END = 'flex-end',
    CENTER = 'center'
}
export enum FlexJustifyContentEnum {
    FLEX_START = 'flex-start',
    FLEX_END = 'flex-end',
    CENTER = 'center',
    SPACE_AROUND = 'space-around',
    SPACE_BETWEEN = 'space-between',
    SPACE_EVENLY = 'space-evenly'
}

export enum TextColorEnum {
    $BLACK = '$black'
}
export enum TextAlignEnum {
    CENTER = 'center'
}
export enum TableBackgroundEnum {
    ACTIVE = 'active',
    INACTIVE = 'inactive'
}
export enum TableContentOverflowWrapEnum {
    ACTIVE = 'active',
    INACTIVE = 'inactive'
}
export enum TextTransformEnum {
    CAPITALIZE = 'capitalize',
    INHERIT = 'inherit',
    LOWERCASE = 'lowercase',
    NONE = 'none',
    UPPERCASE = 'uppercase'
}
// none | [ capitalize | uppercase | lowercase ] || full-width || full-size-kana | math-auto
