import React from 'react';
import { IContactProps } from './types';
import { GlobalFooter } from 'components/organisms/GlobalFooter';
import {
    SCBody,
    SCBodySmall,
    SCContentDirectionWrapper,
    SCContentSectionResponsiveWrapper,
    SCContentSectionWrapper,
    SCContentWrapper,
    SCFooterWrapper,
    SCInputLabel,
    SCPageWrapper,
    SCSubHead
} from 'styles/global-styles';
import { TSButtonSizeEnum, TSButtonVariantsEnum } from 'enums/TSButtonVariants';
import { TSButton } from 'components/atoms/TSButton';
import { FlexAlignItemsEnum, FlexDirectionEnum } from 'enums/StyleTypes';

const Contact = ({ contact }: IContactProps): JSX.Element => {
    return (
        <SCPageWrapper>
            <SCContentWrapper>
                <SCContentDirectionWrapper
                    flexDirection={FlexDirectionEnum.COLUMN}
                    flexAlignment={FlexAlignItemsEnum.FLEX_START}
                >
                    <SCContentSectionResponsiveWrapper
                        flexDirection={FlexDirectionEnum.COLUMN}
                        flexAlignment={FlexAlignItemsEnum.FLEX_START}
                        flexAlignmentResponsive={FlexAlignItemsEnum.FLEX_START}
                        flexGap={'24px'}
                    >
                        <SCSubHead>Contact Us</SCSubHead>
                        <SCContentSectionWrapper
                            flexDirection={FlexDirectionEnum.COLUMN}
                            flexAlignment={FlexAlignItemsEnum.FLEX_START}
                            flexGap={'0'}
                        >
                            <SCBodySmall>Our regular business hours are:</SCBodySmall>
                            <SCBody>Monday to Friday</SCBody>
                            <SCBody>8AM to 5PM CST</SCBody>
                        </SCContentSectionWrapper>
                        <SCInputLabel>
                            Phone Number
                            <a href="tel:855-997-7272">
                                <TSButton
                                    tsVariant={TSButtonVariantsEnum.SLIDE}
                                    size={TSButtonSizeEnum.LARGE}
                                    ariaLabel={'phone number button'}
                                >
                                    (855)-997-7272
                                </TSButton>
                            </a>
                        </SCInputLabel>
                        <SCInputLabel>
                            Email Address
                            <a href="mailto:paymentguard@trustage.com">
                                <TSButton
                                    tsVariant={TSButtonVariantsEnum.SLIDE}
                                    size={TSButtonSizeEnum.LARGE}
                                    ariaLabel={'button for email link'}
                                >
                                    paymentguard@trustage.com
                                </TSButton>
                            </a>
                        </SCInputLabel>
                    </SCContentSectionResponsiveWrapper>
                </SCContentDirectionWrapper>
            </SCContentWrapper>
            <SCFooterWrapper>
                <GlobalFooter />
            </SCFooterWrapper>
        </SCPageWrapper>
    );
};

export { Contact };
