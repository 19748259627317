import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { ClaimsService } from 'api/ClaimsService';
import { DistributorConfigurationStateContext } from 'context/distributorDataContext';
import { FormReview } from 'components/organisms/FormReview';
import { SCTopWrapper } from 'components/organisms/GlobalFooter/styles';
import {
    $darkGray,
    $deepBlue,
    $digitalBlack,
    SCBody,
    SCContentSectionResponsiveWrapper,
    SCContentSectionWrapper,
    SCModalWrapper,
    SCSectionBody,
    SCSectionSubHead,
    SCSubHead
} from 'styles/global-styles';
import { IConfirmAndSubmitProps } from './types';
import { IFormIUData } from 'types/form';
import { AlertLocationTypesEnum, ClaimTypesEnum, TrackerEnum } from 'enums';
import { FormTypeContext } from 'context/formTypeContext';
import { AlertContext, IAlertTypeState } from 'context/Alert';
import { ClaimFormAdvancementContext, IClaimFormAdvancementTypeState } from 'context/ClaimFormAdvancement';
import { ClaimFormResponseContext, IClaimFormResponseTypeState } from 'context/ClaimFormResponse';
import { IFormTypeState } from 'types/claim-types';
import { IClientContextState } from 'types/client-context';
import { EConsentDisclaimer } from 'components/atoms/EConsentDisclaimer';
import ReCAPTCHA from 'react-google-recaptcha';
import { TSButton } from 'components/atoms/TSButton';
import { TSButtonVariantsEnum } from 'enums/TSButtonVariants';
import { SCDisclaimerContent, SCDisclaimerContentWrapper, SCFraudDisclaimer, SCLoaderWrapper } from './styles';
import { TSDialog } from 'components/atoms/TSDialog';
import { ClaimStateEnum } from 'enums/ClaimState';
import { AlertTypeEnum } from 'enums/AlertEnums';
import { AlertCTATypeEnum } from 'enums/AlertEnums';
import { FlexAlignItemsEnum, FlexDirectionEnum, FlexJustifyContentEnum } from 'enums/StyleTypes';
import { BeforeUnload } from 'components/molecules/BeforeUnload';
import { AuthenticationSessionContext } from 'context/authenticationSessionContext';
import { IAuthenticationDataState } from 'types/authenticatation-session';
import { Routes } from 'routes';

const ConfirmAndSubmit = ({ formState, saveFormState }: IConfirmAndSubmitProps): ReactElement => {
    const topRef = useRef<HTMLInputElement>(null);
    const { formType, saveFormType } = React.useContext(FormTypeContext) as IFormTypeState;
    const { contextStateAlert, updateContextStateAlert } = React.useContext(AlertContext) as IAlertTypeState;
    const { authenticationData, updateAuthenticationType } = React.useContext(
        AuthenticationSessionContext
    ) as IAuthenticationDataState;
    const { contextStateClaimFormAdvancement, updateContextStateClaimFormAdvancement } = React.useContext(
        ClaimFormAdvancementContext
    ) as IClaimFormAdvancementTypeState;
    const { contextStateClaimFormResponse, updateContextStateClaimFormResponse } = React.useContext(
        ClaimFormResponseContext
    ) as IClaimFormResponseTypeState;
    const { clientContextDataState } = React.useContext(DistributorConfigurationStateContext) as IClientContextState;
    const [formData, setFormData] = React.useState<IFormIUData | Record<string, unknown>>();
    const [, setModalState] = useState<boolean>(false);
    const [modalEDeliveryState, setModalEDeliveryState] = useState<boolean>(false);
    const [stateFraud_AL_MD, setStateFraud_AL_MD] = useState<boolean>(false);
    const [stateFraud_CA, setStateFraud_CA] = useState<boolean>(false);
    const [stateFraud_CO, setStateFraud_CO] = useState<boolean>(false);
    const [stateFraud_DC, setStateFraud_DC] = useState<boolean>(false);
    const [stateFraud_FL, setStateFraud_FL] = useState<boolean>(false);
    const [stateFraud_NJ, setStateFraud_NJ] = useState<boolean>(false);
    const [stateFraud_NH, setStateFraud_NH] = useState<boolean>(false);
    const [stateFraud_NY, setStateFraud_NY] = useState<boolean>(false);
    const [stateFraud_OH, setStateFraud_OH] = useState<boolean>(false);
    const [stateFraud_PA, setStateFraud_PA] = useState<boolean>(false);
    const [stateFraud_PR, setStateFraud_PR] = useState<boolean>(false);
    const [stateFraud_VI, setStateFraud_VI] = useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const recaptchaRef = React.useRef<ReCAPTCHA>();
    useEffect(() => {
        if (formState) {
            setFormData({
                ...formState
            });
        }
    }, []);
    useEffect(() => {
        if (formData && formData?.eDeliveryConfirmation !== 'true') {
            setFormData({
                ...formData,
                ['eDeliveryConfirmation']: 'true'
            });
            handleSaveFormState(formData);
        }
    }, [formData]);
    useEffect(() => {
        const state = formData?.borrowerMailingState;
        setStateFraud_AL_MD(state === 'AL' || state === 'MD');
        setStateFraud_CA(state === 'CA');
        setStateFraud_CO(state === 'CO');
        setStateFraud_DC(state === 'DC');
        setStateFraud_FL(state === 'FL');
        setStateFraud_NJ(state === 'NJ');
        setStateFraud_NH(state === 'NH');
        setStateFraud_NY(state === 'NY');
        setStateFraud_OH(state === 'OH');
        setStateFraud_PA(state === 'PA');
        setStateFraud_PR(state === 'PR');
        setStateFraud_VI(state === 'VI');
    }, [formData?.borrowerMailingState]);
    const allowedRoutes = [
        `${window.location.origin}${Routes.CALLBACK}`,
        `${process.env.REACT_APP_IDP_ISSUER_URL}`,
        `${process.env.REACT_APP_IDP_ISSUER_URL}/default/v1/logout?id_token_hint`
    ];
    const handleSaveFormState = (formData: IFormIUData | any): any => {
        saveFormState(formData);
    };
    const prepareAndPost = async (formData: any): Promise<void> => {
        const captchaToken = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset();

        if (ClaimTypesEnum.Jobloss === formType.type) {
            const IUData = {
                ClaimType: ClaimTypesEnum.Jobloss,
                Borrower: {
                    NameFirst: formData.borrowerNameFirst,
                    NameLast: formData.borrowerNameLast,
                    Address: formData.borrowerMailingAddress,
                    City: formData.borrowerMailingCity,
                    State: formData.borrowerMailingState,
                    Zipcode: formData.borrowerMailingZip,
                    Email: formData.borrowerEmail,
                    Phone: formData.borrowerPhone.replace(/\D/g, ''),
                    SSNLast4: formData.borrowerSSN ? formData.borrowerSSN : '',
                    DateOfBirth: formData.borrowerDateOfBirth,
                    EDeliveryConfirmation: formData.eDeliveryConfirmation
                },
                EmployerInfo: {
                    CompanyName: formData.employerName,
                    Email: formData.employerEmail ? formData.employerEmail : null,
                    Address: formData.employerAddress,
                    City: formData.employerCity,
                    State: formData.employerState,
                    Zipcode: formData.employerZip,
                    Phone: formData.employerContactNumber.replace(/\D/g, '')
                },
                ClaimInfo: {
                    JoblossDate: formData.iuDateOfJobLoss,
                    JoblossReason: formData.iuJobLossReason,
                    JoblossLastDayWorkedDate: formData.iuDateLastDayWorked,
                    JoblossNotificationDate: formData.iuDateOfJobLossNotification,
                    JoblossOccupation: formData.iuOccupationAtTimeOfSeparation,
                    JoblossLayoffTypicalAtThisTime: formData.iuIsLayoffTypicalAtThisTime
                        ? formData.iuIsLayoffTypicalAtThisTime
                        : '',
                    JoblossKnowledgeOfLayoffWhenHired: formData.iuKnowledgeOfLayoffWhenHired
                        ? formData.iuKnowledgeOfLayoffWhenHired
                        : '',
                    JoblossIsSelfEmployed: formData.iuSelfEmployed ? formData.iuSelfEmployed : null,
                    JoblossQualifiedForStateUnemployment: formData.iuQualifyForStateUnemployment,
                    JoblossRejectionStateBenefitReason: formData.iuRejectionStateBenefitsReason,
                    DistributorName: clientContextDataState.payload.distributorName
                        ? clientContextDataState.payload.distributorName
                        : null,
                    LoanServicerName: clientContextDataState.payload.loanServicerName
                        ? clientContextDataState.payload.loanServicerName
                        : null,
                    Comment: formData.borrowerComments ? formData.borrowerComments : null,
                    ClaimSubmittedRole: formData.iuFiledOnBehalfOf === 'yes' ? 'Distributor' : 'Borrower'
                },
                Submitter: {
                    CompanyName:
                        formData.iuFiledOnBehalfOf === 'yes' && formData.submitterCompanyName
                            ? formData.submitterCompanyName
                            : null,
                    NameFirst:
                        formData.iuFiledOnBehalfOf === 'yes' && formData.submitterNameFirst
                            ? formData.submitterNameFirst
                            : null,
                    NameLast:
                        formData.iuFiledOnBehalfOf === 'yes' && formData.submitterNameLast
                            ? formData.submitterNameLast
                            : null,
                    Email:
                        formData.iuFiledOnBehalfOf === 'yes' && formData.submitterEmail
                            ? formData.submitterEmail
                            : null,
                    Phone:
                        formData.iuFiledOnBehalfOf === 'yes' && formData.submitterPhone
                            ? formData.submitterPhone.replace(/\D/g, '')
                            : null
                },
                CaptchaToken: captchaToken
            };
            postNewTasks(IUData);
        } else if (ClaimTypesEnum.Disability === formType.type) {
            const DisabilityData = {
                ClaimType: ClaimTypesEnum.Disability,
                Borrower: {
                    NameFirst: formData.borrowerNameFirst,
                    NameLast: formData.borrowerNameLast,
                    Address: formData.borrowerMailingAddress,
                    City: formData.borrowerMailingCity,
                    State: formData.borrowerMailingState,
                    Zipcode: formData.borrowerMailingZip,
                    Email: formData.borrowerEmail,
                    Phone: formData.borrowerPhone.replace(/\D/g, ''),
                    SSNLast4: formData.borrowerSSN ? formData.borrowerSSN : '',
                    DateOfBirth: formData.borrowerDateOfBirth,
                    EDeliveryConfirmation: formData.eDeliveryConfirmation
                },
                EmployerInfo: {
                    CompanyName: formData.employerName,
                    Email: formData.employerEmail ? formData.employerEmail : null,
                    Address: formData.employerAddress,
                    City: formData.employerCity,
                    State: formData.employerState,
                    Zipcode: formData.employerZip,
                    Phone: formData.employerContactNumber.replace(/\D/g, '')
                },
                HealthcareProvider: {
                    ContactName: formData.disabilityHealthcareName ? formData.disabilityHealthcareName : null,
                    CompanyName: formData.disabilityHealthcareHospitalName,
                    Email: formData.disabilityHealthcareEmail,
                    Address: formData.disabilityHealthcareAddress,
                    City: formData.disabilityHealthcareCity,
                    State: formData.disabilityHealthcareState,
                    Zipcode: formData.disabilityHealthcareZip,
                    Phone: formData.disabilityHealthcareContactNumber.replace(/\D/g, '')
                },
                ClaimInfo: {
                    DisabilityFirstPhysicianDate: formData.disabilityFirstPhysicianDate,
                    DisabilityJobDuties: formData.disabilityJobDuties ? formData.disabilityJobDuties : null,
                    DisabilityJobClassification: formData.disabilityJobClassification,
                    DisabilityDisabilityBeginDate: formData.disabilityDateOfIncident,
                    DisabilityExpectedBackToWorkDate: formData.disabilityDateExpectedWorking,
                    DisabilityLastWorkedDate: formData.disabilityDateLastWorked,
                    DisabilityExplanation: formData.disabilityExplanationOf,
                    DisabilityTypeOfSurgery: formData.disabilityTypeOfSurgery ? formData.disabilityTypeOfSurgery : null,
                    DisabilityMedicalCategories: formData.disabilityMedicalCategories,
                    DisabilityHealthcareProfessionalName: formData.disabilityHealthcareName,
                    DistributorName: clientContextDataState.payload.distributorName
                        ? clientContextDataState.payload.distributorName
                        : null,
                    LoanServicerName: clientContextDataState.payload.loanServicerName
                        ? clientContextDataState.payload.loanServicerName
                        : null,
                    Comment: formData.borrowerComments ? formData.borrowerComments : null,
                    ClaimSubmittedRole: formData.disabilityFiledOnBehalfOf === 'yes' ? 'Distributor' : 'Borrower'
                },
                Submitter: {
                    CompanyName:
                        formData.disabilityFiledOnBehalfOf === 'yes' && formData.submitterCompanyName
                            ? formData.submitterCompanyName
                            : null,
                    NameFirst:
                        formData.disabilityFiledOnBehalfOf === 'yes' && formData.submitterNameFirst
                            ? formData.submitterNameFirst
                            : null,
                    NameLast:
                        formData.disabilityFiledOnBehalfOf === 'yes' && formData.submitterNameLast
                            ? formData.submitterNameLast
                            : null,
                    Email:
                        formData.disabilityFiledOnBehalfOf === 'yes' && formData.submitterEmail
                            ? formData.submitterEmail
                            : null,
                    Phone:
                        formData.disabilityFiledOnBehalfOf === 'yes' && formData.submitterPhone
                            ? formData.submitterPhone.replace(/\D/g, '')
                            : null
                },
                CaptchaToken: captchaToken
            };
            postNewTasks(DisabilityData);
        }
    };
    const formTypeLabel = formType.type === ClaimTypesEnum.Disability ? 'Disability' : 'Job loss';
    const emailLink = 'paymentguard@trustage.com';
    const postNewTasks = async (payloadData: any): Promise<void> => {
        try {
            setIsLoading(true);
            await ClaimsService.postClaimWithResponse(payloadData).then((response: any): void => {
                setIsLoading(false);
                response &&
                    updateContextStateClaimFormResponse({
                        claimNumber: response.data.payload,
                        formTypeLabel: formType.type,
                        isSuccess: true
                    });
            });
            updateContextStateClaimFormAdvancement({
                reviewState: ClaimStateEnum.CONFIRMATION
            });
        } catch (error) {
            console.error(
                '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::',
                '\n::POST ClaimsService.postClaim API Error::',
                error,
                '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
            );
            setIsLoading(false);
            updateContextStateClaimFormAdvancement({
                reviewState: ClaimStateEnum.CONFIRMATION
            });
            updateContextStateClaimFormResponse({
                formTypeLabel: formType.type,
                isSuccess: false
            });
            updateContextStateAlert({
                type: AlertTypeEnum.ERROR,
                dismissible: true,
                message: 'There was an issue processing your claim.',
                callToAction: 'Contact Us',
                callToActionURL: emailLink,
                callToActionType: AlertCTATypeEnum.EMAIL,
                active: true,
                alertLocation: AlertLocationTypesEnum.GLOBAL
            });
        }
    };
    useEffect(() => {
        topRef.current && isLoading && topRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, [isLoading]);
    // console.info(
    //     '\n::::::::::::::::::::::::::::::::ConfirmAndSubmit:::::::::::::::::::::::::::::::::',
    //     '\n::formData::',
    //     formData,
    //     '\n::formType.type::',
    //     formType.type,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );

    return (
        <SCContentSectionResponsiveWrapper
            flexDirection={FlexDirectionEnum.COLUMN}
            flexAlignment={FlexAlignItemsEnum.FLEX_START}
            flexGap={'24px'}
            sectionWidth={'100%'}
            useRef={topRef}
        >
            {!isLoading && (
                <>
                    <SCSubHead textColor={$deepBlue}>{`File a Claim: ${formTypeLabel}`}</SCSubHead>
                    <SCBody>
                        Please review your claim details. If everything looks right, click the Submit Claim button.
                    </SCBody>
                    <SCSectionSubHead>Review and Submit</SCSectionSubHead>
                    <FormReview formData={formState} />
                    <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} size="invisible" ref={recaptchaRef} />
                    <SCDisclaimerContentWrapper>
                        <SCDisclaimerContent>
                            <SCTopWrapper>
                                <SCFraudDisclaimer>
                                    <b>
                                        FRAUD WARNING: Any person who knowingly presents a false or fraudulent claim for
                                        payment of a loss or benefit, or knowingly presents false information in an
                                        application for insurance may be guilty of a crime and subject to fines and
                                        confinement in prison, and denial of insurance benefits, depending on state law.
                                    </b>
                                </SCFraudDisclaimer>
                                <>
                                    {stateFraud_AL_MD && (
                                        <SCFraudDisclaimer>
                                            <b>Alabama and Maryland Residents:</b> Any person who knowingly or willfully
                                            presents a false or fraudulent claim for payment of a loss or benefit or who
                                            knowingly or willfully presents false information in an application for
                                            insurance is guilty of a crime and may be subject to fines and confinement
                                            in prison.
                                        </SCFraudDisclaimer>
                                    )}
                                    {stateFraud_CA && (
                                        <SCFraudDisclaimer>
                                            <b>California Residents:</b> For your protection California law requires the
                                            following to appear on this form. Any person who knowingly presents false or
                                            fraudulent information to obtain or amend insurance coverage or to make a
                                            claim for the payment of a loss is guilty of a crime and may be subject to
                                            fines and confinement in state prison
                                        </SCFraudDisclaimer>
                                    )}
                                    {stateFraud_CO && (
                                        <SCFraudDisclaimer>
                                            <b>
                                                Colorado Residents: It is unlawful to knowingly provide false,
                                                incomplete, or misleading facts or information to an insurance company
                                                for the purpose of defrauding or attempting to defraud the company.
                                                Penalties may include imprisonment, fines, denial of insurance, and
                                                civil damages. Any insurance company or agent of an insurance company
                                                who knowingly provides false, incomplete, or misleading facts or
                                                information to a policyholder or claimant for the purpose of defrauding
                                                or attempting to defraud the policyholder or claimant with regard to a
                                                settlement or award payable from insurance proceeds shall be reported to
                                                the Colorado division of insurance within the department of regulatory
                                                agencies.
                                            </b>
                                        </SCFraudDisclaimer>
                                    )}
                                    {stateFraud_DC && (
                                        <SCFraudDisclaimer>
                                            <b>District of Columbia Residents:</b> WARNING: It is a crime to provide
                                            false or misleading information to an insurer for the purpose of defrauding
                                            the insurer or any other person. Penalties include imprisonment and/or
                                            fines. In addition, an insurer may deny insurance benefits, if false
                                            information materially related to a claim was provided by the applicant.
                                        </SCFraudDisclaimer>
                                    )}
                                    {stateFraud_FL && (
                                        <SCFraudDisclaimer>
                                            <b>Florida Residents:</b> Any person who knowingly and with intent to
                                            injure, defraud or deceive any insurer files a statement of claim or an
                                            application containing any false, incomplete or misleading information is
                                            guilty of a felony of the third degree.
                                        </SCFraudDisclaimer>
                                    )}
                                    {stateFraud_NJ && (
                                        <SCFraudDisclaimer>
                                            <b>New Jersey Residents:</b> Any person who knowingly files a statement of
                                            claim containing any false or misleading information is subject to criminal
                                            and civil penalties.
                                        </SCFraudDisclaimer>
                                    )}
                                    {stateFraud_NH && (
                                        <SCFraudDisclaimer>
                                            <b>New Hampshire Residents:</b> Any person who, with a purpose to injure,
                                            defraud, or deceive any insurance company, files a statement of claim
                                            containing any false, incomplete, or misleading information is subject to
                                            prosecution and punishment for insurance fraud, as provided in RSA 638:20.
                                        </SCFraudDisclaimer>
                                    )}
                                    {stateFraud_NY && (
                                        <SCFraudDisclaimer>
                                            <b>New York Residents:</b> Any person who knowingly and with intent to
                                            defraud any insurance company or other person files an application for
                                            insurance or statement of claim containing any materially false information,
                                            or conceals for the purpose of misleading, information concerning any fact
                                            material thereto, commits a fraudulent insurance act, which is a crime, and
                                            shall also be subject to civil penalty not to exceed $5,000 and the stated
                                            value of the claim for each such violation.
                                        </SCFraudDisclaimer>
                                    )}
                                    {stateFraud_OH && (
                                        <SCFraudDisclaimer>
                                            <b>Ohio Residents:</b> Any person who, with intent to defraud or knowing
                                            that he is facilitating a fraud against an insurer, submits an application
                                            or files a claim containing a false or deceptive statement is guilty of
                                            insurance fraud.
                                        </SCFraudDisclaimer>
                                    )}
                                    {stateFraud_PA && (
                                        <SCFraudDisclaimer>
                                            <b>Pennsylvania Residents:</b> Any person who knowingly and with intent to
                                            defraud any insurance company or other person files an application for
                                            insurance or a statement of claim containing any materially false
                                            information or conceals for the purpose of misleading, information
                                            concerning any fact material thereto commits a fraudulent insurance act,
                                            which is a crime and subjects such person to criminal and civil penalties.
                                        </SCFraudDisclaimer>
                                    )}
                                    {stateFraud_PR && (
                                        <SCFraudDisclaimer>
                                            <b>Puerto Rico Residents:</b> Any person who knowingly and with the
                                            intention of defrauding presents false information in an insurance
                                            application, or presents, helps, or causes the presentation of a fraudulent
                                            claim for the payment of a loss or any other benefit, or presents more than
                                            one claim for the same damage or loss, shall incur a felony and, upon
                                            conviction, shall be sanctioned for each violation with the penalty of a
                                            fine of not less than five thousand dollars ($5,000) and not more than ten
                                            thousand dollars ($10,000), or a fixed term of imprisonment for three (3)
                                            years, or both penalties. Should aggravating circumstances be present, the
                                            penalty thus established may be increased to a maximum of five (5) years, if
                                            extenuating circumstances are present, it may be reduced to a minimum of two
                                            (2) years.
                                        </SCFraudDisclaimer>
                                    )}
                                    {stateFraud_VI && (
                                        <SCFraudDisclaimer>
                                            <b>Virginia Residents:</b> Any person who, with the intent to defraud or
                                            knowing that he is facilitating a fraud against an insurer, submits an
                                            application or files a claim containing a false or deceptive statement may
                                            have violated state law.
                                        </SCFraudDisclaimer>
                                    )}
                                </>
                                <SCFraudDisclaimer>
                                    <b>
                                        By clicking “Submit Claim”, you acknowledge and agree to the following terms and
                                        conditions:
                                    </b>
                                    <ul>
                                        <li>The information provided is accurate to the best of your knowledge.</li>
                                        <li>
                                            You have reviewed and agree to the terms and conditions of the{' '}
                                            <a
                                                onClick={(): void => {
                                                    setModalEDeliveryState(true);
                                                }}
                                            >
                                                Electronic Communications Consent and Agreement
                                            </a>{' '}
                                            and consent to conducting transactions related to this claim in an
                                            electronic form which includes receiving all electronic communications,
                                            electronic records, electronic signatures for the communications, and
                                            electronic notices and disclosures electronically at the email address
                                            provided.
                                        </li>
                                    </ul>
                                </SCFraudDisclaimer>
                            </SCTopWrapper>
                        </SCDisclaimerContent>
                    </SCDisclaimerContentWrapper>
                    <SCContentSectionWrapper
                        flexDirection={FlexDirectionEnum.ROW}
                        flexAlignment={FlexAlignItemsEnum.FLEX_END}
                        flexJustifyContent={FlexJustifyContentEnum.FLEX_END}
                        sectionWidth={'100%'}
                    >
                        <TSButton
                            disabled={false}
                            onClick={(): void => {
                                setModalState(true);
                                prepareAndPost(formData);
                            }}
                            ariaLabel={'Submit Claim'}
                            tsVariant={TSButtonVariantsEnum.ARROW}
                            stepValue={4}
                        >
                            Submit Claim
                        </TSButton>
                    </SCContentSectionWrapper>
                    <BeforeUnload
                        isLoggedIn={authenticationData.isAuthenticated ? authenticationData.isAuthenticated : false}
                        cancelHeaderButtonText={'Close'}
                        modalBody={
                            <SCContentSectionWrapper
                                flexDirection={FlexDirectionEnum.COLUMN}
                                flexAlignment={FlexAlignItemsEnum.FLEX_START}
                                flexGap={'24px'}
                            >
                                <SCSectionSubHead textColor={$digitalBlack}>Unsaved Changes</SCSectionSubHead>
                                <SCSectionBody>
                                    You have unsaved claim information, continuing may cause your information to be lost
                                </SCSectionBody>
                            </SCContentSectionWrapper>
                        }
                        nativeDialogue={true}
                        customDialogue={true}
                        modalEnabled={true}
                        continueButtonText={'Proceed without saving'}
                        cancelButtonText={'Return to page'}
                        onContinue={(): void => {
                            saveFormType({ type: undefined, reviewState: ClaimStateEnum.ACTIVE });
                        }}
                        allowedRoutes={allowedRoutes}
                        onCancel={(): void => {}}
                    />
                </>
            )}
            {isLoading && (
                <SCContentSectionWrapper
                    flexDirection={FlexDirectionEnum.COLUMN}
                    flexAlignment={FlexAlignItemsEnum.CENTER}
                    justify-content={'center'}
                    sectionWidth={'100%'}
                >
                    <SCLoaderWrapper>
                        <CircularProgress
                            sx={{
                                color: (theme): string => {
                                    return theme.palette.mode === 'light'
                                        ? ({ $darkGray } as unknown as string)
                                        : ({ $darkGray } as unknown as string);
                                },
                                animationDuration: '1550ms'
                            }}
                            size={100}
                            thickness={4}
                        />
                    </SCLoaderWrapper>
                </SCContentSectionWrapper>
            )}
            {modalEDeliveryState && (
                <SCModalWrapper>
                    <TSDialog
                        ariaLabel="econsent-dialog"
                        id="econsent-dialog"
                        closeButtonText="Done"
                        open={modalEDeliveryState}
                        handleClose={(): void => {
                            setModalEDeliveryState(false);
                        }}
                    >
                        <EConsentDisclaimer />
                    </TSDialog>
                </SCModalWrapper>
            )}
        </SCContentSectionResponsiveWrapper>
    );
};

export { ConfirmAndSubmit };
