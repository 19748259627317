import React, { useEffect, ReactElement, useState } from 'react';
import {
    $digitalBlack,
    SCContentSectionResponsiveWrapper,
    SCContentSectionWrapper,
    SCHeroHeader,
    SCSectionBody,
    SCSectionInfo,
    SCSectionSubHead,
    SCUnorderedList
} from 'styles/global-styles';
import { DistributorConfigurationStateContext } from 'context/distributorDataContext';
import { FormTypeContext } from 'context/formTypeContext';
import { ClaimFormAdvancementContext, IClaimFormAdvancementTypeState } from 'context/ClaimFormAdvancement';
import { ClaimFormResponseContext, IClaimFormResponseTypeState } from 'context/ClaimFormResponse';
import { FormIUStateContext } from 'context/formIUStateContext';
import { FormDisabilityStateContext } from 'context/formDisabilityStateContext';
import { FormJoblossProgressStateContext } from 'context/formProgressJoblossContext';
import { FormDisabilityProgressStateContext } from 'context/formProgressDisabilityContext';
import { AlertContext, IAlertTypeState } from 'context/Alert';
import { AuthenticationSessionContext } from 'context/authenticationSessionContext';
import { IAuthenticationDataState } from 'types/authenticatation-session';
import { FAQSummary } from '../FAQSummary';
import { TSButton } from 'components/atoms/TSButton';
import { TSDialog } from 'components/atoms/TSDialog';
import { IClientContextState } from 'types/client-context';
import { SCContactInfoBottom, SCContactInfoTop } from 'components/pages/ClaimForm/styles';
import { IFormTypeState } from 'types/claim-types';
import { IFormDisabilityState, IFormIUState } from 'types/form';
import { IFormDisabilityProgressState, IFormJoblossProgressState } from 'types/form-progress';
import { TSButtonSizeEnum, TSButtonVariantsEnum } from 'enums/TSButtonVariants';
import { ClaimStateEnum } from 'enums/ClaimState';
import { FAQGroupName } from 'enums/FAQGroupName';
import { FlexAlignItemsEnum, FlexDirectionEnum, FlexJustifyContentEnum } from 'enums/StyleTypes';
import { Routes } from 'routes/Routes';
import { useHistory } from 'react-router-dom';
import { BeforeUnload } from 'components/molecules/BeforeUnload';
import { AlertLocationTypesEnum, TrackerEnum } from 'enums';

const ClaimConfirmation = (): ReactElement => {
    const { clientContextDataState } = React.useContext(DistributorConfigurationStateContext) as IClientContextState;
    const { formType, saveFormType } = React.useContext(FormTypeContext) as IFormTypeState;
    const { updateContextStateClaimFormAdvancement } = React.useContext(
        ClaimFormAdvancementContext
    ) as IClaimFormAdvancementTypeState;
    const { authenticationData, updateAuthenticationType } = React.useContext(
        AuthenticationSessionContext
    ) as IAuthenticationDataState;
    const { contextStateClaimFormResponse } = React.useContext(ClaimFormResponseContext) as IClaimFormResponseTypeState;
    const { reinitialiseFormIUState } = React.useContext(FormIUStateContext) as IFormIUState;
    const { reinitialiseFormDisabilityState } = React.useContext(FormDisabilityStateContext) as IFormDisabilityState;
    const { resetFormJoblossProgressState, updateFormJoblossProgressCompletionStatus } = React.useContext(
        FormJoblossProgressStateContext
    ) as IFormJoblossProgressState;
    const { resetFormDisabilityProgressState, updateFormDisabilityProgressCompletionStatus } = React.useContext(
        FormDisabilityProgressStateContext
    ) as IFormDisabilityProgressState;
    const { contextStateAlert, updateContextStateAlert } = React.useContext(AlertContext) as IAlertTypeState;
    const history = useHistory();
    const [displayClaimSurvey, setDisplayClaimSurvey] = useState(false);
    const qualtricsURI = `${process.env.REACT_APP_QUALTRICS}?CLAIM_NUMBER=${contextStateClaimFormResponse.claimNumber}&CLAIM_TYPE=${contextStateClaimFormResponse.formTypeLabel}&COMPANY=${clientContextDataState?.payload?.distributorName}`;
    const allowedRoutes = [
        `${window.location.origin}${Routes.CALLBACK}`,
        `${process.env.REACT_APP_IDP_ISSUER_URL}`,
        `${process.env.REACT_APP_IDP_ISSUER_URL}/default/v1/logout?id_token_hint`
    ];
    const dispatchClaimProgressEvent = (nameOfClaimStep: string): void => {
        document.dispatchEvent(
            new CustomEvent(TrackerEnum.PAYMENT_GUARD_CLAIMPROCESS, {
                detail: {
                    claimType: formType.type,
                    distributorName: clientContextDataState.payload.distributorName,
                    id: nameOfClaimStep
                }
            })
        );
    };
    useEffect(() => {
        if (contextStateClaimFormResponse?.isSuccess) {
            reinitialiseFormIUState();
            reinitialiseFormDisabilityState();
            resetFormJoblossProgressState();
            resetFormDisabilityProgressState();
            updateFormJoblossProgressCompletionStatus(false);
            updateFormDisabilityProgressCompletionStatus(false);
            let timer: ReturnType<typeof setTimeout> | null = null;
            timer && clearTimeout(timer);
            timer = setTimeout(() => {
                setDisplayClaimSurvey(true);
            }, 3000);
            dispatchClaimProgressEvent(TrackerEnum.CLAIMSTEP_SUBMITTED);
        } else {
            dispatchClaimProgressEvent(TrackerEnum.CLAIMSTEP_SUBMIT_ATTEMPT_FAILED);
        }
    }, [contextStateClaimFormResponse]);
    // console.info(
    //     '\n::::::::::::::::::::::::::::::::ClaimConfirmation:::::::::::::::::::::::::::::::::',
    //     '\n::contextStateClaimFormResponse::',
    //     contextStateClaimFormResponse,
    //     '\n::distributorConfigurationDataState::',
    //     distributorConfigurationDataState,
    //     '\n::qualtricsURI::',
    //     qualtricsURI,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );

    return (
        <SCContentSectionResponsiveWrapper
            flexDirection={FlexDirectionEnum.COLUMN}
            flexAlignment={FlexAlignItemsEnum.FLEX_START}
            flexGap={'36px'}
        >
            <SCContentSectionWrapper
                flexDirection={FlexDirectionEnum.COLUMN}
                flexAlignment={FlexAlignItemsEnum.FLEX_START}
                flexGap={'80px'}
            >
                <SCContentSectionWrapper
                    flexDirection={FlexDirectionEnum.COLUMN}
                    flexAlignment={FlexAlignItemsEnum.FLEX_START}
                    flexJustifyContent={FlexJustifyContentEnum.FLEX_START}
                    flexGap={'36px'}
                    sectionWidth={'100%'}
                >
                    {contextStateClaimFormResponse?.isSuccess ? (
                        <>
                            <SCContentSectionWrapper
                                flexDirection={FlexDirectionEnum.COLUMN}
                                flexAlignment={FlexAlignItemsEnum.FLEX_START}
                                flexJustifyContent={FlexJustifyContentEnum.FLEX_START}
                                flexGap={'36px'}
                                sectionWidth={'100%'}
                            >
                                <SCContentSectionWrapper
                                    flexDirection={FlexDirectionEnum.ROW}
                                    flexAlignment={FlexAlignItemsEnum.FLEX_START}
                                    flexJustifyContent={FlexJustifyContentEnum.SPACE_BETWEEN}
                                    flexGap={'0'}
                                >
                                    <SCHeroHeader>
                                        Your {contextStateClaimFormResponse?.formTypeLabel} claim has been submitted!
                                    </SCHeroHeader>
                                    <SCContentSectionWrapper
                                        flexDirection={FlexDirectionEnum.COLUMN}
                                        flexAlignment={FlexAlignItemsEnum.FLEX_END}
                                        flexJustifyContent={FlexJustifyContentEnum.SPACE_BETWEEN}
                                        flexGap={'0'}
                                        sectionWidth={'25%'}
                                    >
                                        <SCContactInfoTop>
                                            <h3> 1-855-997-7272</h3>
                                            <h4> 8:00 AM - 5:00 PM CT</h4>
                                            <h4>Monday - Friday</h4>
                                        </SCContactInfoTop>
                                    </SCContentSectionWrapper>
                                </SCContentSectionWrapper>
                                <SCUnorderedList>
                                    <li>We’ve sent you an email as a confirmation of your claim request submission.</li>
                                    <li>
                                        If you do not receive a confirmation email from us within the next 24 hours,
                                        please call us at 855-997-7272.
                                    </li>
                                    <li>
                                        We will now begin processing your claim. Claims are typically processed within
                                        10 business days.
                                    </li>
                                </SCUnorderedList>
                                <TSButton
                                    tsVariant={TSButtonVariantsEnum.ARROW}
                                    ariaLabel={''}
                                    onClick={(): void => {
                                        history.push(Routes.HOME);
                                        saveFormType({
                                            type: formType.type,
                                            reviewState: ClaimStateEnum.INACTIVE
                                        });
                                        updateContextStateClaimFormAdvancement({
                                            reviewState: ClaimStateEnum.INACTIVE
                                        });
                                    }}
                                >
                                    Back to Home
                                </TSButton>
                            </SCContentSectionWrapper>
                            <TSDialog
                                id={'claim-form-survey'}
                                ariaLabel={'claim form survey'}
                                closeButtonText={'Close Survey'}
                                open={displayClaimSurvey}
                                fullscreen={true}
                                handleClose={(): void => {
                                    setDisplayClaimSurvey(false);
                                }}
                            >
                                <iframe src={qualtricsURI} width="100%" height="99%"></iframe>
                            </TSDialog>
                        </>
                    ) : (
                        <>
                            <SCContentSectionWrapper
                                flexDirection={FlexDirectionEnum.COLUMN}
                                flexAlignment={FlexAlignItemsEnum.FLEX_START}
                                flexJustifyContent={FlexJustifyContentEnum.FLEX_START}
                                flexGap={'36px'}
                                sectionWidth={'100%'}
                            >
                                <SCContentSectionWrapper
                                    flexDirection={FlexDirectionEnum.ROW}
                                    flexAlignment={FlexAlignItemsEnum.FLEX_START}
                                    flexJustifyContent={FlexJustifyContentEnum.SPACE_BETWEEN}
                                    flexGap={'0'}
                                >
                                    <SCContentSectionWrapper
                                        flexDirection={FlexDirectionEnum.COLUMN}
                                        flexAlignment={FlexAlignItemsEnum.FLEX_START}
                                        flexJustifyContent={FlexJustifyContentEnum.SPACE_BETWEEN}
                                        flexGap={'36'}
                                    >
                                        <SCSectionSubHead>There was an issue processing your claim</SCSectionSubHead>
                                        <SCSectionInfo>
                                            Unfortunately there was an issue processing your claim at this time.
                                        </SCSectionInfo>
                                        <TSButton
                                            tsVariant={TSButtonVariantsEnum.ARROW}
                                            ariaLabel={''}
                                            onClick={(): void => {
                                                updateContextStateAlert({
                                                    alertLocation: AlertLocationTypesEnum.GLOBAL
                                                });
                                                updateContextStateClaimFormAdvancement({
                                                    reviewState: ClaimStateEnum.REVIEW
                                                });
                                            }}
                                        >
                                            Return to Your Claim
                                        </TSButton>
                                    </SCContentSectionWrapper>
                                    <SCContentSectionWrapper
                                        flexDirection={FlexDirectionEnum.COLUMN}
                                        flexAlignment={FlexAlignItemsEnum.FLEX_END}
                                        flexJustifyContent={FlexJustifyContentEnum.SPACE_BETWEEN}
                                        flexGap={'0'}
                                        sectionWidth={'25%'}
                                    >
                                        <SCContactInfoTop>
                                            <h3>1-855-997-7272</h3>
                                            <h4>8:00 AM - 5:00 PM CT</h4>
                                            <h4>Monday - Friday</h4>
                                        </SCContactInfoTop>
                                    </SCContentSectionWrapper>
                                </SCContentSectionWrapper>
                            </SCContentSectionWrapper>
                            <BeforeUnload
                                isLoggedIn={
                                    authenticationData.isAuthenticated ? authenticationData.isAuthenticated : false
                                }
                                cancelHeaderButtonText={'Close'}
                                modalBody={
                                    <SCContentSectionWrapper
                                        flexDirection={FlexDirectionEnum.COLUMN}
                                        flexAlignment={FlexAlignItemsEnum.FLEX_START}
                                        flexGap={'24px'}
                                    >
                                        <SCSectionSubHead textColor={$digitalBlack}>Unsaved Changes</SCSectionSubHead>
                                        <SCSectionBody>
                                            You have unsaved claim information, continuing may cause your information to
                                            be lost
                                        </SCSectionBody>
                                    </SCContentSectionWrapper>
                                }
                                nativeDialogue={true}
                                customDialogue={true}
                                modalEnabled={!contextStateClaimFormResponse?.isSuccess}
                                continueButtonText={'Proceed without saving'}
                                cancelButtonText={'Return to page'}
                                onContinue={(): void => {
                                    saveFormType({ type: undefined, reviewState: ClaimStateEnum.ACTIVE });
                                }}
                                allowedRoutes={allowedRoutes}
                                onCancel={(): void => {}}
                            />
                        </>
                    )}
                    <SCContactInfoBottom>
                        <h3> 1-855-997-7272</h3>
                        <h4> 8:00 AM - 5:00 PM CT</h4>
                        <h4>Monday - Friday</h4>
                    </SCContactInfoBottom>
                </SCContentSectionWrapper>
                <SCContentSectionWrapper
                    flexDirection={FlexDirectionEnum.COLUMN}
                    flexAlignment={FlexAlignItemsEnum.FLEX_START}
                    flexGap={'36px'}
                >
                    {clientContextDataState?.payload?.client_content && (
                        <FAQSummary
                            distributorConfigurationData={clientContextDataState.payload.client_content}
                            groupName={FAQGroupName.claim}
                        ></FAQSummary>
                    )}
                </SCContentSectionWrapper>
            </SCContentSectionWrapper>
        </SCContentSectionResponsiveWrapper>
    );
};

export { ClaimConfirmation };
