import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { DialogProps } from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import { ITSDialogProps } from './types';
import { TSButton } from '../TSButton';
import { TSButtonSizeEnum, TSButtonVariantsEnum } from 'enums/TSButtonVariants';
import {
    SCDialogActionButton,
    SCDialogActionsWrapper,
    SCDialogCloseButton,
    SCDialogContentWrapper,
    SCDialogHeader,
    SCDialogHeaderLabel,
    SCDialogHeadline,
    SCDialogWrapper
} from './styles';
import { XIcon } from 'components/atoms/XIcon';
import { SCContentSectionWrapper } from 'styles/global-styles';
import { FlexAlignItemsEnum, FlexDirectionEnum, FlexJustifyContentEnum } from 'enums/StyleTypes';
import { Alert } from 'components/molecules/Alert';
import { AlertLocationTypesEnum } from 'enums';

const TSDialog = ({
    actionChildren,
    children,
    id,
    ariaLabel,
    bannerHeaderText,
    bannerHeaderCloseButtonText,
    contentHeaderText,
    content,
    closeButtonText,
    open,
    hideSecondaryCloseButton,
    hideDialogActions,
    dialogSize,
    fullscreen,
    handleClose,
    alert,
    handleAlertDismiss
}: ITSDialogProps): ReactElement => {
    const [scroll, setScroll] = useState<DialogProps['scroll']>('paper');
    const descriptionElementRef = useRef<HTMLElement>(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);
    // console.info(
    //     '\n::::::::::::::::::::::::::::::::TSDialog:::::::::::::::::::::::::::::::::',
    //     '\n::dialogSize::',
    //     dialogSize,
    //     // '\n::size::',
    //     // size,
    //     // '\n::scroll::',
    //     // scroll,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );

    return (
        <SCDialogWrapper
            $fullscreen={fullscreen === true ? 'true' : 'false'}
            fullScreen={fullscreen ? fullscreen : false}
            fullWidth={!!fullscreen}
            $maxWidth={dialogSize}
            maxWidth={dialogSize}
            open={open}
            onClose={handleClose}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            aria-label={ariaLabel}
            id={id}
        >
            <SCDialogHeader id="SCDialogHeaderid">
                <SCDialogCloseButton>
                    <TSButton
                        ariaLabel="dialog close"
                        tsVariant={TSButtonVariantsEnum.TEXT}
                        onClick={handleClose}
                        size={TSButtonSizeEnum.SMALL}
                    >
                        <SCContentSectionWrapper
                            flexDirection={FlexDirectionEnum.ROW}
                            flexAlignment={FlexAlignItemsEnum.CENTER}
                            flexJustifyContent={FlexJustifyContentEnum.CENTER}
                        >
                            <XIcon size={'lg'} />
                            {bannerHeaderCloseButtonText}
                        </SCContentSectionWrapper>
                    </TSButton>
                </SCDialogCloseButton>
                <SCDialogHeaderLabel>{bannerHeaderText}</SCDialogHeaderLabel>
            </SCDialogHeader>
            <Alert
                alert={alert}
                handleDismiss={handleAlertDismiss}
                styleMessageLeftPadding={'16px'}
                alertLocation={AlertLocationTypesEnum.DIALOG_PRIMARY}
            />
            <Alert styleMessageLeftPadding={'16px'} alertLocation={AlertLocationTypesEnum.DIALOG_SECONDARY} />
            <SCDialogContentWrapper dividers>
                <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
                    <SCDialogHeadline>{contentHeaderText}</SCDialogHeadline>
                    <span>{content}</span>
                </DialogContentText>
                {children}
            </SCDialogContentWrapper>
            {!hideDialogActions && (
                <SCDialogActionsWrapper>
                    {!hideSecondaryCloseButton && (
                        <SCDialogActionButton>
                            <TSButton
                                ariaLabel="dialog close"
                                tsVariant={TSButtonVariantsEnum.SIMPLE}
                                onClick={handleClose}
                            >
                                {closeButtonText}
                            </TSButton>
                        </SCDialogActionButton>
                    )}
                    {actionChildren && actionChildren}
                </SCDialogActionsWrapper>
            )}
        </SCDialogWrapper>
    );
};

export { TSDialog };
