import { useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { HeadCell, ClaimSurveyHeadCell, HeadCellExternal, csvHeadCell } from 'components/pages/ClaimsManagement';

/**
 * @name isNumeric
 * @description Only process strings. !isNaN(str) && use type coercion to parse the _entirety_ of the string
 * (`parseFloat` alone does not do this) and ensure strings of whitespace fail.
 * @return boolean
 * @param value
 */
export const isNumeric = <T extends object>(value: string): boolean => {
    const num = Number(value);
    return !isNaN(num);
};
export const regExIsNumeric = (str: string): boolean => {
    return /^\d+$/.test(str);
};
export const makeUUID = (): string => {
    const s4 = (): string => {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    const st =
        new Date().getTime().toString(16).slice(0, 11) +
        (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1, 2);
    return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${st}`;
};
const setDateUniversalFormat = (dateString: string): Date => {
    const [date, time] = dateString.split(' ');
    dateString = `${date}T${time}Z`;
    return new Date(dateString);
};
export const dateFormat = (dateString: any): string => {
    dateString &&
        isNaN(dateString) &&
        dateString.split('T').length === 1 &&
        (dateString = setDateUniversalFormat(dateString as string));
    const month = dateString && dateString.getMonth !== undefined && ('0' + (dateString.getMonth() + 1)).slice(-2);
    const date = dateString && dateString.getDate !== undefined && ('0' + dateString.getDate()).slice(-2);
    const year = dateString && dateString.getFullYear !== undefined && dateString.getFullYear();
    let hours = dateString && dateString.getHours() !== undefined && dateString.getHours();
    let minutes = dateString && dateString.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? ('0' as unknown as number) + minutes : minutes;
    return `${month}/ ${date}/ ${year}  ${('0' + hours).slice(-2)}:${minutes} ${ampm}`;
};
export const getEnumKey = <E, K extends string>(enumCollection: { [key in K]: E }, lookup: string | null): string => {
    return Object.keys(enumCollection)[Object.values(enumCollection).indexOf(`${lookup}` as string)];
};
export const isObjectEmpty = (obj: object): boolean => {
    return obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;
};
dayjs.extend(utc);
dayjs.extend(timezone);
export const flattenObject = (obj: object, prefix = ''): object => {
    const flatObject: object = {};
    Object.keys(obj).forEach((key, value) => {
        typeof obj[key] === 'object' && obj[key] !== null
            ? Object.assign(flatObject, flattenObject(obj[key], prefix))
            : (flatObject[prefix + key] = obj[key]);
    });
    return flatObject;
};
export const convertToCSV = (
    arr: any[],
    headCells:
        | any[]
        | readonly HeadCell[]
        | readonly ClaimSurveyHeadCell[]
        | readonly HeadCellExternal[]
        | readonly csvHeadCell[]
): string => {
    const array: string[][] = [Object.keys(flattenObject(arr[0]))].concat(arr);
    const alphanumericRegEx = RegExp(/^[a-z0-9]+$/i);
    const regExReplacement = (object: number | string[]): any => {
        for (const [key, value] of Object.entries(object)) {
            object[key] =
                regExIsNumeric(value) && alphanumericRegEx.test(value.toLowerCase().replace(/\s/g, ''))
                    ? value
                    : regExIsNumeric(value) && value.includes('/')
                    ? value
                    : regExIsNumeric(value) && !value.includes(',')
                    ? `="${value}"`
                    : regExIsNumeric(value) && value.includes(',')
                    ? `="${value.replaceAll(',', '')}"`
                    : value && value.includes('–')
                    ? `"${value.replaceAll(/–/g, '-')}"`
                    : value && value.includes('’')
                    ? `"${value.replaceAll(/’/g, `'`)}"`
                    : value
                    ? `"${value.replaceAll(/[\\"]+/g, ``)}"`
                    : value
                    ? `${value}`
                    : ``;
        }
        return object;
    };
    array[0] = headCells.map((x) => {
        return x.label;
    });
    return array
        .map((object: string[]) => {
            /**
             * While mapping array run the regEx replacement, and then rerun, in case any new CSV issues were created in the first run.
             */
            object = regExReplacement(object);
            object = regExReplacement(object);
            const flatObject = flattenObject(object);
            return Object.values(flatObject).toString();
        })
        .join('\n');
};
export const createCSVDownloadLink = (csv, filename): void => {
    const csvFile: Blob = new Blob([csv], { type: 'text/csv' });
    const downloadLink: HTMLAnchorElement = document.createElement('a');
    downloadLink.download = `${filename}.csv`;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    downloadLink.click();
};
/** Checks to see if a string contains possible HTML tags */
export const doesContainHTML = (stringToCheck: string): boolean => {
    //Return true if a left arrow followed by a right arrow exist in the string.
    return stringToCheck.match(/^.*<.*>/) != null;
};
export const debounce = <T extends unknown[]>(func: (...args: T) => void, delay: number): ((...args: T) => void) => {
    let timer: ReturnType<typeof setTimeout> | null = null;
    return (...args: T) => {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            func.call(null, ...args);
        }, delay);
    };
};
export const useStateDebounced = <T>(
    initialValue: T,
    delay: number
): readonly [T, T, (value: T | ((prevState: T) => T)) => void] => {
    const [inputValue, _setInputValue] = useState<T>(initialValue);
    const [debouncedInputValue, setDebouncedInputValue] = useState<T>(initialValue);
    const memoizedDebounce = useMemo(
        () =>
            debounce((value: T) => {
                setDebouncedInputValue(value);
            }, delay),
        [delay]
    );
    const setInputValue = (value: T | ((prevState: T) => T)): void => {
        if (value instanceof Function) {
            _setInputValue((p) => {
                const mutated = value(p);
                memoizedDebounce(mutated);
                return mutated;
            });
        } else {
            _setInputValue(value);
            memoizedDebounce(value);
        }
    };
    return [inputValue, debouncedInputValue, setInputValue] as const;
};
export const usePrevious = <T>(value: T): T | undefined => {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};
export const isPhoneIdentifierType = (borrowerIdentifierType: number): boolean => {
    return borrowerIdentifierType === 2;
};
export const isLast4SSNIdentifierType = (borrowerIdentifierType: number): boolean => {
    return !isPhoneIdentifierType(borrowerIdentifierType);
};
export const checkImageReference = async (urlValue: string | undefined): Promise<boolean> => {
    if (!urlValue) {
        return false;
    }
    const res: Response = await fetch(urlValue);
    const buff: Blob = await res.blob();
    return buff.type.startsWith('image/');
};
/**
 * @name titleCase
 * @description - Convert string to title case, i.e. first letter capitalized, following letters lower case.
 * @param string
 */
export const titleCase = (string: string): string => {
    return string[0].toUpperCase() + string.slice(1).toLowerCase();
};
/**
 * @name areAllFalse
 * @description - Check if all values in array are false.
 * @param arr
 */
export const areAllFalse = (arr: any[]): boolean => {
    return arr.every((element) => !element);
};
/**
 * @name allObjectValuesFalse
 * @description - Check if all values in object are false.
 * @param obj
 * @return boolean - return true if all values false
 */
export const allObjectValuesFalse = (obj): boolean => {
    return Object.values(obj).every((value) => !value);
};
/**
 * @name objectHasProperty
 * @description - Check for object value with strongly typed check for undefined, or null.
 * @param obj - Object reference
 * @param key - Key of Object reference to check for value.
 * @return boolean - return false for undefined or null, returns true if property has value that is not undefined, or null,
 */
export const objectHasProperty = <T extends object, K extends keyof T>(obj: T, key: K): boolean => {
    return Object.prototype.hasOwnProperty.call(obj, key) && obj[key] !== undefined && obj[key] !== null;
};
/**
 * @name propertyHasValue
 * @description - Check for if key exists in object, if key exists check for non-empty, i.e. not 0 or ''.
 * @param obj - Object reference
 * @param key - Key of Object reference to check for value.
 * @return boolean - return false if key does not exist in object or if key does key does exist and its value is 0 or '',
 * returns true if property has value,
 */
export const propertyHasValue = <T extends object, K extends keyof T>(obj: T, key: K): boolean => {
    const keyValue = typeof obj[key] === 'string' && isNumeric(obj[key] as string) ? +obj[key] : obj[key];
    return key in obj && obj[key] !== '' && obj[key] !== 0 && keyValue !== 0;
};
/**
 * @name objectKeyHasValue
 * @description - Check for object value with strongly typed check for undefined, or null. If key exists and property is
 * not undefined or null, check for non-empty property, i.e. not 0 or ''.
 * @param obj - Object reference
 * @param key - Key of Object reference to check for value.
 * @return boolean - return false for undefined or null or if key does not exist in object or if key does key does exist
 * and its value is 0 or '', returns true if property has none empty value,
 */
export const objectKeyHasValue = <T extends object, K extends keyof T>(obj: T, key: K): boolean => {
    return objectHasProperty(obj, key) && propertyHasValue(obj, key);
};
/**
 * @name stringHasValue
 * @description - Check string for value with strongly typed check for undefined, or null. If key exists and property is
 * not undefined, null or empty string, i.e. not 0 or ''.
 * @return boolean - return false for undefined, null or empty string, returns true if property has value and is a string,
 * @param value - input value to check.
 */
export const stringHasValue = (value: string | number | null | undefined): boolean => {
    return typeof value === 'string' && value.trim().length > 0;
};
/*
// List of types with various type checks
const myObjectTest = {
    a: '',
    b: undefined,
    c: null,
    d: 0,
    d2: '0.00',
    e: false,
    f: true,
    g: 1,
    h: 'string'
};
console.info(
    '\n::::::::::::::::::::::::::::::::Property Checks:::::::::::::::::::::::::::::::::',
    "\n::objectHasProperty(myObjectTest, 'a') = ''::",
    objectHasProperty(myObjectTest, 'a'),
    "\n::objectHasProperty(myObjectTest, 'b') = undefined::",
    objectHasProperty(myObjectTest, 'b'),
    "\n::objectHasProperty(myObjectTest, 'c') = null::",
    objectHasProperty(myObjectTest, 'c'),
    "\n::objectHasProperty(myObjectTest, 'd') = 0::",
    objectHasProperty(myObjectTest, 'd'),
    "\n::objectHasProperty(myObjectTest, 'd2') = '0.00'::",
    objectHasProperty(myObjectTest, 'd2'),
    "\n::objectHasProperty(myObjectTest, 'e') = false::",
    objectHasProperty(myObjectTest, 'e'),
    "\n::objectHasProperty(myObjectTest, 'f') = true::",
    objectHasProperty(myObjectTest, 'f'),
    "\n::objectHasProperty(myObjectTest, 'h') = 'string'::",
    objectHasProperty(myObjectTest, 'h'),
    '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::',
    "\n::propertyHasValue(myObjectTest, 'a') = ''::",
    propertyHasValue(myObjectTest, 'a'),
    "\n::propertyHasValue(myObjectTest, 'b') = undefined::",
    propertyHasValue(myObjectTest, 'b'),
    "\n::propertyHasValue(myObjectTest, 'c') = null::",
    propertyHasValue(myObjectTest, 'c'),
    "\n::propertyHasValue(myObjectTest, 'd') = 0::",
    propertyHasValue(myObjectTest, 'd'),
    "\n::propertyHasValue(myObjectTest, 'd2') = '0.00'::",
    propertyHasValue(myObjectTest, 'd2'),
    "\n::propertyHasValue(myObjectTest, 'e') = false::",
    propertyHasValue(myObjectTest, 'e'),
    "\n::propertyHasValue(myObjectTest, 'f') = true::",
    propertyHasValue(myObjectTest, 'f'),
    "\n::propertyHasValue(myObjectTest, 'g') = 1::",
    propertyHasValue(myObjectTest, 'g'),
    "\n::propertyHasValue(myObjectTest, 'h') = 'string'::",
    propertyHasValue(myObjectTest, 'h'),
    '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::',
    "\n::objectKeyHasValue(myObjectTest, 'a') = ''::",
    objectKeyHasValue(myObjectTest, 'a'),
    "\n::objectKeyHasValue(myObjectTest, 'b') = undefined::",
    objectKeyHasValue(myObjectTest, 'b'),
    "\n::objectKeyHasValue(myObjectTest, 'c') = null::",
    objectKeyHasValue(myObjectTest, 'c'),
    "\n::objectKeyHasValue(myObjectTest, 'd') = 0::",
    objectKeyHasValue(myObjectTest, 'd'),
    "\n::objectKeyHasValue(myObjectTest, 'd2') = '0.00'::",
    objectKeyHasValue(myObjectTest, 'd2'),
    "\n::objectKeyHasValue(myObjectTest, 'e') = false::",
    objectKeyHasValue(myObjectTest, 'e'),
    "\n::objectKeyHasValue(myObjectTest, 'f') = true::",
    objectKeyHasValue(myObjectTest, 'f'),
    "\n::objectKeyHasValue(myObjectTest, 'g') = 1::",
    objectKeyHasValue(myObjectTest, 'g'),
    "\n::objectKeyHasValue(myObjectTest, 'h') = 'string'::",
    objectKeyHasValue(myObjectTest, 'h'),
    '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
);*/
