import * as React from 'react';
import { IClaimEditTypeState, IClaimEditType } from './ClaimEditTypes';
import { IChildrenProps } from 'types/children';
import { IClaimsManagementClaimBenefitsData } from 'types/claims-management';
import { ClaimBenefitDecisionEnum } from 'enums';

export const ClaimEditContext = React.createContext<IClaimEditTypeState | null>(null);
const ClaimEditProvider: React.FC<IChildrenProps> = ({ children }) => {
    const initialClaimBenefitsState = [
        {
            ClaimBenefitId: '',
            ClaimPolicyRelationshipId: '00000000-0000-0000-0000-000000000000',
            ExternalSystemIdentifier: '',
            CertificateEffectiveDateTimeUTC: '',
            AmountPayable: '',
            DecisionStatus: ClaimBenefitDecisionEnum.OPEN,
            DecisionDateTimeUTC: '',
            DecisionReason: '',
            DecisionEmailSentDateTimeUTC: '',
            FastTracked: false,
            DecisionAdditionalInfo: '',
            IsDeleted: false
        }
    ];
    const [stateClaimEditBenefits, setStateClaimEditBenefits] =
        React.useState<IClaimsManagementClaimBenefitsData[]>(initialClaimBenefitsState);
    const updateContextStateClaimEditBenefits = (claimEditBenefitsData: IClaimsManagementClaimBenefitsData[]): void => {
        if (claimEditBenefitsData.length > 0) {
            const claimsManagementClaimBenefitsData: IClaimsManagementClaimBenefitsData[] =
                structuredClone(claimEditBenefitsData);
            setStateClaimEditBenefits(claimsManagementClaimBenefitsData);
        }
    };
    const updateContextStateWithNewClaimBenefits = (
        claimEditBenefitsData: IClaimsManagementClaimBenefitsData[]
    ): void => {
        const newClaimEdit = (stateClaimEdit.ClaimBenefits = claimEditBenefitsData);
        setStateClaimEditBenefits(claimEditBenefitsData);
    };
    const updateClaimEditBenefitRecord = (ClaimEditBenefitData: IClaimsManagementClaimBenefitsData): void => {
        const existingRecord: IClaimsManagementClaimBenefitsData | undefined = stateClaimEditBenefits?.find(
            (record) => {
                record.DecisionStatus =
                    record.DecisionStatus === ('OPEN' as ClaimBenefitDecisionEnum)
                        ? ClaimBenefitDecisionEnum.OPEN
                        : record.DecisionStatus;
                return record.ClaimBenefitId === ClaimEditBenefitData.ClaimBenefitId;
            }
        );
        if (!existingRecord) {
            updateContextStateClaimEditBenefits(stateClaimEdit.ClaimBenefits);
        } else {
            const claimEditBenefitDataAssignment: IClaimsManagementClaimBenefitsData = {
                ClaimBenefitId:
                    ClaimEditBenefitData && ClaimEditBenefitData.ClaimBenefitId
                        ? ClaimEditBenefitData && ClaimEditBenefitData.ClaimBenefitId
                        : existingRecord && existingRecord.ClaimBenefitId
                        ? existingRecord && existingRecord.ClaimBenefitId
                        : '',
                ClaimPolicyRelationshipId:
                    ClaimEditBenefitData && ClaimEditBenefitData.ClaimPolicyRelationshipId
                        ? ClaimEditBenefitData && ClaimEditBenefitData.ClaimPolicyRelationshipId
                        : existingRecord && existingRecord.ClaimPolicyRelationshipId
                        ? existingRecord && existingRecord.ClaimPolicyRelationshipId
                        : '00000000-0000-0000-0000-000000000000',
                ExternalSystemIdentifier: ClaimEditBenefitData && ClaimEditBenefitData.ExternalSystemIdentifier,
                CertificateEffectiveDateTimeUTC:
                    ClaimEditBenefitData && ClaimEditBenefitData.CertificateEffectiveDateTimeUTC
                        ? ClaimEditBenefitData && ClaimEditBenefitData.CertificateEffectiveDateTimeUTC
                        : '',
                AmountPayable:
                    ClaimEditBenefitData && ClaimEditBenefitData.AmountPayable
                        ? ClaimEditBenefitData && ClaimEditBenefitData.AmountPayable
                        : '',
                DecisionStatus:
                    ClaimEditBenefitData && ClaimEditBenefitData.DecisionStatus
                        ? ClaimEditBenefitData?.DecisionStatus
                        : ClaimBenefitDecisionEnum.OPEN,
                DecisionDateTimeUTC:
                    ClaimEditBenefitData && ClaimEditBenefitData.DecisionDateTimeUTC
                        ? ClaimEditBenefitData && ClaimEditBenefitData.DecisionDateTimeUTC
                        : '',
                DecisionReason:
                    ClaimEditBenefitData && ClaimEditBenefitData.DecisionReason
                        ? ClaimEditBenefitData && ClaimEditBenefitData.DecisionReason
                        : '',
                DecisionEmailSentDateTimeUTC:
                    ClaimEditBenefitData && ClaimEditBenefitData.DecisionEmailSentDateTimeUTC
                        ? ClaimEditBenefitData && ClaimEditBenefitData.DecisionEmailSentDateTimeUTC
                        : '',
                FastTracked: ClaimEditBenefitData && ClaimEditBenefitData.FastTracked,
                DecisionAdditionalInfo: ClaimEditBenefitData && ClaimEditBenefitData.DecisionAdditionalInfo,
                IsDeleted: ClaimEditBenefitData.IsDeleted ? ClaimEditBenefitData.IsDeleted : existingRecord?.IsDeleted
            };
            const mapMatchResult =
                Array.isArray(stateClaimEditBenefits) && stateClaimEditBenefits.length
                    ? stateClaimEditBenefits?.map((record) => {
                          return record.ClaimBenefitId === claimEditBenefitDataAssignment.ClaimBenefitId
                              ? claimEditBenefitDataAssignment
                              : !existingRecord
                              ? claimEditBenefitDataAssignment
                              : record;
                      })
                    : new Array(claimEditBenefitDataAssignment);
            mapMatchResult.map((iterationValue) =>
                iterationValue.DecisionStatus === ('OPEN' as ClaimBenefitDecisionEnum)
                    ? { ...iterationValue, DecisionStatus: ClaimBenefitDecisionEnum.OPEN }
                    : iterationValue
            );

            setStateClaimEditBenefits(mapMatchResult);
            updateContextStateWithNewClaimBenefits(mapMatchResult);
        }
    };
    const [stateClaimEdit, setStateClaimEdit] = React.useState<IClaimEditType>({
        nameFirst: '',
        nameLast: '',
        distributorName: '',
        loanServicerName: '',
        claimNumber: -1,
        claimStatus: '',
        jobLossReason: '',
        last4SSN: '',
        claimCreateDate: '',
        peril: '',
        loanNumber: -1,
        certificateEffectiveDate: '',
        dateIncurred: '',
        dateOfClaimDecision: '',
        claimDecision: '',
        amountPayable: '',
        disablingCondition: '',
        decisionReason: '',
        dateDecisionEmailSent: '',
        fastTrack: '',
        additionalInfo: '',
        eConsent: '',
        nameOfClaimSpecialist: '',
        notes: '',
        borrowerIdentifierKey: '',
        editStatus: false,
        ClaimBenefits: stateClaimEditBenefits,
        emailAddress: '',
        allClaimDetailsLookupIndex: -1
    });
    /* console.info(
        '\n:::::::::::::::::::::::::ClaimEditContext:::::::::::::::::::::::::::',
        '\n::stateClaimEdit::',
        stateClaimEdit,
        '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    );*/
    const stateObject: IClaimEditTypeState = {
        contextStateClaimEdit: stateClaimEdit,
        updateContextStateClaimEdit: setStateClaimEdit,
        updateContextStateClaimEditBenefit: updateClaimEditBenefitRecord,
        updateContextStateClaimEditBenefits: setStateClaimEditBenefits
    };
    return <ClaimEditContext.Provider value={stateObject}>{children}</ClaimEditContext.Provider>;
};

export default ClaimEditProvider;
