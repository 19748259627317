import React, { ReactElement } from 'react';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import AlertProvider from 'context/Alert/AlertContext';
import AuthenticationSessionProvider from 'context/authenticationSessionContext';
import CertificateStateProvider from 'context/CertificateState/CertificateStateContext';
import DistributorConfigurationStateProvider from 'context/distributorDataContext';
import ClaimEditProvider from 'context/ClaimEdit/ClaimEditContext';
import ClaimAdjudicationConfigsProvider from 'context/ClaimAdjudicationConfigs/ClaimAdjudicationConfigsContext';
import ClaimFormAdvancementProvider from 'context/ClaimFormAdvancement/ClaimFormAdvancementContext';
import ClaimFormResponseProvider from 'context/ClaimFormResponse/ClaimFormResponseContext';
import ClaimLookupDataStateProvider from 'context/claimLookupDataContext';
import ClaimsManagementStateProvider from 'context/claimsManagementContext';
import FormDisabilityProgressStateProvider from 'context/formProgressDisabilityContext';
import FormDisabilityStateProvider from 'context/formDisabilityStateContext';
import FormIUStateProvider from 'context/formIUStateContext';
import FormJoblossProgressStateProvider from 'context/formProgressJoblossContext';
import FormNavigationProvider from 'context/formNavigationContext';
import FormTypeProvider from 'context/formTypeContext';
import InternalDataRequiredProvider from 'context/InternalDataRequired/InternalDataRequiredContext';
import OverlayTypeProvider from 'context/overlayContext';
import ThemeProvider from 'context/globalThemeContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { GlobalThemeWrapper } from 'components/organisms/GlobalThemeWrapper';
import { Overlay } from 'components/organisms/Overlay';
import { TSGlobalHeader } from 'components/organisms/TSGlobalHeader';
import { RouterSwitch } from 'routes';
import 'styles/styles.css';
import { Security } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { oktaAuthClient } from 'OktaAuth';

function App(): ReactElement {
    const history = useHistory();
    const restoreOriginalUri = async (_oktaAuth, originalUri): Promise<void> => {
        history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    };
    // console.info(
    //     '\n:::::::::::::::::::::::::::::APP::::::::::::::::::::::::::::::::::::',
    //     '\n::process.env.NODE_ENV::',
    //     process.env.NODE_ENV,
    //     '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    // );

    return (
        <AuthenticationSessionProvider>
            <ThemeProvider>
                <FormNavigationProvider>
                    <FormTypeProvider>
                        <FormIUStateProvider>
                            <FormDisabilityStateProvider>
                                <FormJoblossProgressStateProvider>
                                    <FormDisabilityProgressStateProvider>
                                        <DistributorConfigurationStateProvider>
                                            <ClaimLookupDataStateProvider>
                                                <ClaimsManagementStateProvider>
                                                    <ClaimAdjudicationConfigsProvider>
                                                        <ClaimEditProvider>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <OverlayTypeProvider>
                                                                    <AlertProvider>
                                                                        <InternalDataRequiredProvider>
                                                                            <ClaimFormAdvancementProvider>
                                                                                <ClaimFormResponseProvider>
                                                                                    <CertificateStateProvider>
                                                                                        <Security
                                                                                            oktaAuth={oktaAuthClient}
                                                                                            restoreOriginalUri={
                                                                                                restoreOriginalUri
                                                                                            }
                                                                                        >
                                                                                            <GlobalThemeWrapper>
                                                                                                <Overlay>
                                                                                                    <Router>
                                                                                                        <TSGlobalHeader></TSGlobalHeader>
                                                                                                        <RouterSwitch />
                                                                                                    </Router>
                                                                                                </Overlay>
                                                                                            </GlobalThemeWrapper>
                                                                                        </Security>
                                                                                    </CertificateStateProvider>
                                                                                </ClaimFormResponseProvider>
                                                                            </ClaimFormAdvancementProvider>
                                                                        </InternalDataRequiredProvider>
                                                                    </AlertProvider>
                                                                </OverlayTypeProvider>
                                                            </LocalizationProvider>
                                                        </ClaimEditProvider>
                                                    </ClaimAdjudicationConfigsProvider>
                                                </ClaimsManagementStateProvider>
                                            </ClaimLookupDataStateProvider>
                                        </DistributorConfigurationStateProvider>
                                    </FormDisabilityProgressStateProvider>
                                </FormJoblossProgressStateProvider>
                            </FormDisabilityStateProvider>
                        </FormIUStateProvider>
                    </FormTypeProvider>
                </FormNavigationProvider>
            </ThemeProvider>
        </AuthenticationSessionProvider>
    );
}
const AppWithRouterAccess = (): ReactElement => (
    <Router>
        <App />
    </Router>
);
export default AppWithRouterAccess;
