export enum AlertTypeEnum {
    WARNING = 'warning',
    ERROR = 'error',
    INFO = 'info',
    SUCCESS = 'success'
}
export enum AlertCTATypeEnum {
    LINK = 'link',
    EMAIL = 'email',
    NONE = 'none'
}
export enum AlertLocationTypesEnum {
    GLOBAL = 'global',
    DIALOG_PRIMARY = 'dialog-primary',
    DIALOG_SECONDARY = 'dialog-secondary'
}
