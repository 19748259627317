import * as React from 'react';
import { IInternalDataRequiredTypeState, IInternalDataRequiredType } from './InternalDataRequiredTypes';
import { IChildrenProps } from 'types/children';

export const InternalDataRequiredContext = React.createContext<IInternalDataRequiredTypeState | null>(null);
const InternalDataRequiredProvider: React.FC<IChildrenProps> = ({ children }) => {
    const initialClaimBenefitsState = {
        ClaimRequiredErrors: false,
        InternalDataRequiredFields: {
            AmountPayableRequiredStateError: false,
            CertificateEffectiveDateTimeUTCStateError: false,
            ClaimSpecialistNameRequiredStateError: false,
            DecisionDateTimeUTCRequiredStateError: false,
            DecisionEmailSentDateTimeUTCRequiredStateError: false,
            DecisionReasonRequiredStateError: false,
            DisablingConditionRequiredStateError: false,
            ExternalSystemIdentifierRequiredStateError: false,
            FastTrackedRequiredStateError: false,
            IncurredDateRequiredStateError: false,
            NotesRequiredErrorState: false
        }
    };
    const [stateInternalDataRequired, setStateInternalDataRequired] =
        React.useState<IInternalDataRequiredType>(initialClaimBenefitsState);
    const updateContextStateInternalDataRequired = (data: IInternalDataRequiredType): void => {
        const updatedClaimBenefitsState: IInternalDataRequiredType = {
            ClaimRequiredErrors: data?.ClaimRequiredErrors ? data.ClaimRequiredErrors : false,
            InternalDataRequiredFields: {
                AmountPayableRequiredStateError: data?.InternalDataRequiredFields.AmountPayableRequiredStateError
                    ? data.InternalDataRequiredFields.AmountPayableRequiredStateError
                    : false,
                CertificateEffectiveDateTimeUTCStateError: data?.InternalDataRequiredFields
                    .CertificateEffectiveDateTimeUTCStateError
                    ? data.InternalDataRequiredFields.CertificateEffectiveDateTimeUTCStateError
                    : false,
                ClaimSpecialistNameRequiredStateError: data?.InternalDataRequiredFields
                    .ClaimSpecialistNameRequiredStateError
                    ? data.InternalDataRequiredFields.ClaimSpecialistNameRequiredStateError
                    : false,
                DecisionDateTimeUTCRequiredStateError: data?.InternalDataRequiredFields
                    .DecisionDateTimeUTCRequiredStateError
                    ? data.InternalDataRequiredFields.DecisionDateTimeUTCRequiredStateError
                    : false,
                DecisionEmailSentDateTimeUTCRequiredStateError: data?.InternalDataRequiredFields
                    .DecisionEmailSentDateTimeUTCRequiredStateError
                    ? data.InternalDataRequiredFields.DecisionEmailSentDateTimeUTCRequiredStateError
                    : false,
                DecisionReasonRequiredStateError: data?.InternalDataRequiredFields.DecisionReasonRequiredStateError
                    ? data.InternalDataRequiredFields.DecisionReasonRequiredStateError
                    : false,
                DisablingConditionRequiredStateError: data?.InternalDataRequiredFields
                    .DisablingConditionRequiredStateError
                    ? data.InternalDataRequiredFields.DisablingConditionRequiredStateError
                    : false,
                ExternalSystemIdentifierRequiredStateError: data?.InternalDataRequiredFields
                    .ExternalSystemIdentifierRequiredStateError
                    ? data.InternalDataRequiredFields.ExternalSystemIdentifierRequiredStateError
                    : false,
                FastTrackedRequiredStateError: data?.InternalDataRequiredFields.FastTrackedRequiredStateError
                    ? data.InternalDataRequiredFields.FastTrackedRequiredStateError
                    : false,
                IncurredDateRequiredStateError: data?.InternalDataRequiredFields.IncurredDateRequiredStateError
                    ? data.InternalDataRequiredFields.IncurredDateRequiredStateError
                    : false,
                NotesRequiredErrorState: data?.InternalDataRequiredFields.NotesRequiredErrorState
                    ? data.InternalDataRequiredFields.NotesRequiredErrorState
                    : false
            }
        };
        setStateInternalDataRequired(updatedClaimBenefitsState);
    };
    const stateObject: IInternalDataRequiredTypeState = {
        contextStateInternalDataRequired: stateInternalDataRequired,
        updateContextStateInternalDataRequired: updateContextStateInternalDataRequired
    };
    return <InternalDataRequiredContext.Provider value={stateObject}>{children}</InternalDataRequiredContext.Provider>;
};

export default InternalDataRequiredProvider;
